import { Injectable } from '@angular/core';
import {
  Appcues,
  AppcuesPlugin,
  DidHandleURLResponse,
} from '@appcues/capacitor';
import { BehaviorSubject, Observable } from 'rxjs';
import { Script } from '../models/script.model';

export type AppcuesStatus = 'UNAVAILABLE' | 'INITIALIZING' | 'READY' | 'FAILED';

export interface IdentifyOptionsProperties {
  userName: string;
  isTMUser: string;
  customerNumber: string;
  divisionNumber: string;
  customerType: string;
  siteCustomization: string;
  userKind: string;
  surveyUrl: string;
}

export interface IdentifyOptions {
  userId: string;
  properties: IdentifyOptionsProperties;
}

export interface IdentifyOptionsNative {
  userId: string;
  userName: string;
  isTMUser: string;
  customerNumber: string;
  divisionNumber: string;
  customerType: string;
  siteCustomization: string;
  userKind: string;
  surveyUrl: string;
  properties: IdentifyOptionsProperties;
}

interface AppcuesWeb extends AppcuesPlugin {
  page(): void;
}

/**
 * Service to manage Appcues integration for both web and native environments.
 * Provides methods to initialize, identify users, track pages/screens, and handle URLs.
 */
@Injectable({
  providedIn: 'root',
})
export class AppcuesService {
  private static SCRIPT_TAG_NAME_ATTR = 'appcuesTag';
  private readonly _initialized$: Observable<AppcuesStatus>;
  private readonly _initializedSubject: BehaviorSubject<AppcuesStatus> =
    new BehaviorSubject<AppcuesStatus>('UNAVAILABLE');
  private _appcuesInstance!: AppcuesPlugin | AppcuesWeb;

  constructor() {
    this._initialized$ = this._initializedSubject.asObservable();
  }

  /**
   * Initializes Appcues for a web environment by appending a script tag.
   * @param window The browser Window object
   * @param srcLocation URL of the Appcues script to load
   */
  public initializeWeb(window: Window, srcLocation: string) {
    if (this._isInitializable()) {
      this._initializedSubject.next('INITIALIZING');
      this._appendToDocument(window.document, srcLocation).then(
        (script: Script) => {
          if (script.loaded) {
            this._appcuesInstance = window['Appcues'];
            this._initializedSubject.next('READY');
          } else {
            this._initializedSubject.next('FAILED');
          }
        },
      );
    }
  }

  /**
   * Initializes Appcues for a native environment.
   * @param accountId The Appcues account ID
   * @param applicationId The Appcues application ID
   * @param loggingEnabled Whether to enable logging in the Appcues config
   */
  public initializeNative(
    accountId: string,
    applicationId: string,
    loggingEnabled: boolean,
  ) {
    if (this._isInitializable()) {
      this._initializedSubject.next('INITIALIZING');
      Appcues.initialize({
        accountId,
        applicationId,
        config: { logging: loggingEnabled },
      })
        .then(() => {
          this._appcuesInstance = Appcues;
          this._initializedSubject.next('READY');
        })
        .catch(error => {
          this._initializedSubject.next('FAILED');
          throw error;
        });
    }
  }

  /** Checks if Appcues can be initialized (only if currently unavailable or failed) */
  private _isInitializable() {
    const status: AppcuesStatus = this._initializedSubject.getValue();
    return status === 'UNAVAILABLE' || status === 'FAILED';
  }

  get currentStatus(): AppcuesStatus {
    return this._initializedSubject.getValue();
  }

  public statusAsObservable(): Observable<AppcuesStatus> {
    return this._initialized$;
  }

  public didHandleUrl(url: string): Promise<boolean> {
    return this.currentStatus === 'READY'
      ? this._appcuesInstance
          .didHandleURL({ url })
          .then((response: DidHandleURLResponse) => response.handled)
      : Promise.resolve(false);
  }

  public identify(
    properties: IdentifyOptions | IdentifyOptionsNative,
  ): Promise<void> {
    return this.currentStatus === 'READY'
      ? this._appcuesInstance.identify(properties)
      : Promise.resolve();
  }

  public page(): void {
    if (
      this.currentStatus === 'READY' &&
      this._isWebProxy(this._appcuesInstance)
    ) {
      return this._appcuesInstance.page();
    }
  }

  /**
   * Resets the Appcues instance.
   * @returns Promise that resolves when reset is complete
   */
  public reset(): Promise<void> {
    return this.currentStatus === 'READY'
      ? this._appcuesInstance.reset()
      : Promise.resolve();
  }

  /**
   * Tracks a screen view in the native environment.
   * @param screenTitle The title of the screen
   * @returns Promise that resolves when screen tracking is complete
   */
  public screen(screenTitle: string): Promise<void> {
    return this.currentStatus === 'READY' &&
      this._isNativePlugin(this._appcuesInstance)
      ? this._appcuesInstance.screen({
        title: screenTitle,
        })
      : Promise.resolve();
  }

  /**
   * Appends the Appcues script to the document head.
   * @param document The DOM Document object
   * @param srcLocation The script source URL
   * @returns Promise resolving with script load status
   */
  private _appendToDocument(
    document: Document,
    srcLocation: string,
  ): Promise<Script> {
    return new Promise((resolve, _reject) => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = srcLocation;
      document.getElementsByTagName('head')[0].appendChild(script);
      script.onload = () => {
        resolve({ loaded: true, name: AppcuesService.SCRIPT_TAG_NAME_ATTR });
      };

      script.onerror = error =>
        resolve({ loaded: false, name: AppcuesService.SCRIPT_TAG_NAME_ATTR });
    });
  }

  /**
   * Type guard to ensure the Appcues instance is for web.
   * @param appcues The Appcues instance to check
   * @returns True if the instance is AppcuesWeb
   */
  private _isWebProxy(
    appcues: AppcuesPlugin | AppcuesWeb,
  ): appcues is AppcuesWeb {
    return 'page' in appcues;
  }

  /**
   * Type guard to ensure the Appcues instance is for native.
   * @param appcues The Appcues instance to check
   * @returns True if the instance is AppcuesPlugin
   */
  // type guard to make sure screen() is only called on Appcues plugin
  private _isNativePlugin(
    appcues: AppcuesPlugin | AppcuesWeb,
  ): appcues is AppcuesPlugin {
    return !('page' in appcues);
  }
}
