import { RecentPurchase } from '@usf/list-types';
import {
  CustomerPill,
  ProductContract,
  ProductPropertiesEnum,
  ProductSummary,
} from '@usf/product-types';
import { RecentPurchasePopoverComponent } from '../../shared/components/popovers/recent-purchase/recent-purchase-popover.component';
import { ChipTypeEnum } from '../../shared/components/usf-chip/models/chip-type.enum';
import { TagProduct } from '../../shared/components/usf-product-tag-list/models/tag-product.model';
import { RecentPurchaseHelper } from '../../shared/helpers/recent-purchase.helpers';
import {
  ProductTagDisplay,
  ProductTagDisplayOrder,
} from '../models/product-tag.model';
import {
  CONTRACT_PILLS_ENUM,
  CONTRACT_PILLS,
} from '../models/contract-pills.enum';
import { ContractPill } from '../models/contract-pills.model';
import { ProductDetailStatusData } from '../../shared/components/product-detail-status-tags-info/product-detail-status-tags-info.component';
import { ProductDetailViewModel } from '../models/product-detail-view-model';
import { JitConfigMessage } from '../models/jit-config-message.model';
import {
  getCESMessageLong,
  getDirectMessage,
  getJITConfig,
} from './product-summary.utils';
import { Product } from '../../shared/models/product.model';
import { buildAlertMessage } from './product-alert-message.utils';
import { UsfChipPopoverOptions } from '@shared/components/usf-chip/models/usf-chip-popover-options.model';

export const getDisplayTags = (
  isMslRestricted: boolean,
  productSummary: ProductSummary,
  productContract: ProductContract,
  customerPill: CustomerPill,
  recentPurchase?: RecentPurchase,
  isGuestUser: boolean = false,
): TagProduct[] => {
  const displayOrder = ProductTagDisplayOrder;
  return Array.from(productSummary?.properties ?? [])
    .sort((a, b) => {
      return displayOrder.indexOf(a) - displayOrder.indexOf(b);
    })
    .map(tag => {
      const guestUserTags = [
        ProductPropertiesEnum.direct,
        ProductPropertiesEnum.scoop,
        ProductPropertiesEnum.locallySourced,
      ];
      if (isGuestUser && !guestUserTags.includes(tag)) {
        return null;
      }
      switch (tag) {
        case ProductPropertiesEnum.contract:
          return productContract ? getContractTag(productContract) : null;
        case ProductPropertiesEnum.customerPill:
          return !!customerPill
            ? {
                content: customerPill?.pillText,
                type: ChipTypeEnum.customerPill,
                textColor: getCustomerPillTextColor(
                  customerPill?.htmlColorCode,
                ),
                backgroundColor: customerPill?.htmlColorCode,
                description: customerPill?.pillDescription,
                clickable: false,
              }
            : null;
        case ProductPropertiesEnum.direct:
          return {
            content: ProductTagDisplay.direct,
            type: ChipTypeEnum.image,
            clickable: false,
            iconConfig: {
              name: 'DIRECT',
              iconWidth: 4.5,
            },
            iconUrl: 'assets/icon/direct.svg',
          };
        case ProductPropertiesEnum.locallySourced:
          return {
            content: ProductTagDisplay.locallySourced,
            type: ChipTypeEnum.default,
            clickable: false,
          };
        case ProductPropertiesEnum.dwo:
          return {
            content: ProductTagDisplay.dwo,
            type: ChipTypeEnum.default,
            clickable: false,
          };
        case ProductPropertiesEnum.recentlyPurchased:
          if (
            RecentPurchaseHelper.formatRecentPurchase(recentPurchase).length > 0
          ) {
            return {
              content: ProductTagDisplay.recentPurchase,
              type: ChipTypeEnum.default,
              clickable: false,
              popoverOptions: !!recentPurchase
                ? ({
                    component: RecentPurchasePopoverComponent,
                    componentProps: {
                      recentPurchase,
                    },
                    cssClass: 'recent-purchase-popover',
                    mode: 'ios',
                    showBackdrop: true,
                    backdropDismiss: true,
                    inline: false,
                  } as UsfChipPopoverOptions)
                : undefined,
            };
          }
          return null;
        case ProductPropertiesEnum.onOrderGuideOrOnShoppingList:
          return {
            content: ProductTagDisplay.onMyLists,
            type: ChipTypeEnum.default,
            clickable: false,
          };
        case ProductPropertiesEnum.scoop:
          return {
            content: ProductTagDisplay.scoop,
            type: ChipTypeEnum.image,
            clickable: false,
            iconConfig: {
              name: 'SCOOP',
              iconWidth: 4.313,
            },
            iconUrl: 'assets/icon/scoop.svg',
          };
        case ProductPropertiesEnum.onMasterList:
          return {
            content: isMslRestricted
              ? ProductTagDisplay.onMasterListRestricted
              : ProductTagDisplay.onMasterList,
            type: ChipTypeEnum.default,
            clickable: false,
          };
        default:
          return null;
      }
    })
    .filter(displayTag => !!displayTag);
};

export const getContractTagV1 = (
  productContract: ProductContract,
): TagProduct => {
  let type;
  let iconUrl;
  let iconConfig;

  const pillType = getContractPillType(
    productContract?.iconDescription,
    productContract?.clientConcept,
  );

  if (pillType) {
    type = ChipTypeEnum.image;
    iconUrl = pillType.iconUrl;
    iconConfig = {
      name: productContract?.iconDescription,
      iconWidth: pillType.size,
    };
  } else {
    type = ChipTypeEnum.contract;
  }

  return {
    content: `${productContract?.iconDescription} - Contract`,
    type,
    clickable: false,
    iconUrl,
    iconConfig,
  };
};

export const getContractTagV2 = (
  productContract: ProductContract,
): TagProduct => {
  if (!!productContract?.iconId) {
    let type = ChipTypeEnum.contractImage;
    let iconUrl = `https://www.usfoods.com/content/dam/usf/UX/library/icons/moxe/${productContract?.iconId}.svg`;
    let iconConfig = {
      name: productContract?.iconDescription,
    };
    return {
      content: `${productContract?.iconDescription} - Contract`,
      type,
      clickable: false,
      iconUrl,
      iconConfig,
    };
  }
  // If the version value is 2 and there is no remote asset available, display the product as if there is no contract
  return null;
};

export const getContractTag = (
  productContract: ProductContract,
): TagProduct => {
  return productContract.version === 2
    ? getContractTagV2(productContract)
    : getContractTagV1(productContract);
};

export const getContractPillType = (
  itemDescription: string,
  clientConcept: string,
): ContractPill => {
  if (!itemDescription) {
    return undefined;
  }
  const upperCaseItemDescription = itemDescription?.toUpperCase();
  if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.orangeVizient) {
    return CONTRACT_PILLS.orangeVizient;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.greenVizient) {
    return CONTRACT_PILLS.greenVizient;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.NEW) {
    return CONTRACT_PILLS.NEW;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.CMA) {
    return CONTRACT_PILLS.CMA;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.GBUY) {
    return CONTRACT_PILLS.GBUY;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.CMAR) {
    return CONTRACT_PILLS.CMAR;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.TERM) {
    return CONTRACT_PILLS.TERM;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.NEWR) {
    return CONTRACT_PILLS.NEWR;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.CMP) {
    return CONTRACT_PILLS.CMP;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.HTR) {
    return CONTRACT_PILLS.HTR;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.HTS1) {
    return CONTRACT_PILLS.HTS1;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.HTS2) {
    return CONTRACT_PILLS.HTS2;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.HTS3) {
    return CONTRACT_PILLS.HTS3;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.HMS) {
    return CONTRACT_PILLS.HMS;
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.DOLL) {
    if (clientConcept?.toUpperCase() === CONTRACT_PILLS_ENUM.LEGENDS) {
      return CONTRACT_PILLS.LEGENDS;
    } else if (
      clientConcept?.toUpperCase() === CONTRACT_PILLS_ENUM.BJS ||
      clientConcept?.toUpperCase() === CONTRACT_PILLS_ENUM.BJSC
    ) {
      return CONTRACT_PILLS.BJS;
    } else if (
      clientConcept?.toUpperCase() === CONTRACT_PILLS_ENUM.FIRST_WATCH
    ) {
      return CONTRACT_PILLS.FIRST_WATCH;
    } else {
      return undefined;
    }
  } else if (upperCaseItemDescription === CONTRACT_PILLS_ENUM.DLR2) {
    if (
      clientConcept?.toUpperCase() === CONTRACT_PILLS_ENUM.BJS ||
      clientConcept?.toUpperCase() === CONTRACT_PILLS_ENUM.BJSC
    ) {
      return CONTRACT_PILLS.BJS;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

export const getProductDetailStatusData = (
  product: ProductDetailViewModel,
  isMslRestricted: boolean,
  isOgRestricted: boolean,
  isDirectAllowReturnsEnabled: boolean,
  isProprietaryUIEnabled: boolean,
): ProductDetailStatusData => {
  let vendorMessage: string;
  let jitConfig: JitConfigMessage;
  let recentPurchaseRecords = RecentPurchaseHelper.formatRecentPurchase(
    product?.recentPurchase,
  );

  jitConfig = getJITConfig({
    summary: product?.summary,
    inventory: product?.inventory,
  } as Product);
  vendorMessage = getVendorMessageForProductDetailStatusData(
    product,
    isDirectAllowReturnsEnabled,
  );

  return {
    hasPromotions: !!product?.promotionDetails,
    vendorMessage,
    jitConfig,
    contractPill: getContractPill(product),
    recentPurchaseRecords,
    isMslRestricted,
    masterLists: product?.masterLists,
    lists: product?.lists,
    alertMessage: buildAlertMessage(
      product,
      false,
      isMslRestricted,
      isOgRestricted,
      isProprietaryUIEnabled,
    ),
    claims: product?.detail?.claims,
    summary: product?.summary,
    contract: product?.contract,
    customerPill: getCustomerPill(product),
  } as ProductDetailStatusData;
};

export const getContractPill = (
  product: ProductDetailViewModel,
): TagProduct => {
  let contractPill: TagProduct;
  product?.productTags?.forEach(tag => {
    if (
      tag?.content === `${tag?.iconConfig?.name} - Contract` ||
      tag?.type === ChipTypeEnum.contract
    ) {
      contractPill = tag;
    }
  });
  return contractPill;
};

const getCustomerPill = (product: ProductDetailViewModel): TagProduct => {
  return product?.productTags?.find(
    tag => tag?.type === ChipTypeEnum.customerPill,
  );
};

export const getVendorMessageForProductDetailStatusData = (
  product: ProductDetailViewModel,
  isDirectAllowReturnsEnabled: boolean,
): string => {
  let vendorMessage: string;
  if (product?.summary?.properties?.has(ProductPropertiesEnum.ces)) {
    vendorMessage = getCESMessageLong(product);
  }
  if (product?.summary?.properties?.has(ProductPropertiesEnum.direct)) {
    vendorMessage = getDirectMessage(product, isDirectAllowReturnsEnabled);
  }
  return vendorMessage;
};

export const getCustomerPillPopoverOptions = (
  description: string,
): UsfChipPopoverOptions | undefined => {
  return !!description
    ? ({
        inline: true,
        inlinePopoverContent: description,
      } as UsfChipPopoverOptions)
    : undefined;
};

export const getCustomerPillTextColor = (
  backgroundHexColorCode: string,
): string => {
  const isSixDigitHextCode = /^#(([0-9a-fA-F]{2}){3})$/.test(
    backgroundHexColorCode,
  );
  if (!isSixDigitHextCode) {
    return '#000000';
  }

  const hexRadix = 16;
  const red = parseInt(
    backgroundHexColorCode[1] + backgroundHexColorCode[2],
    hexRadix,
  );
  const green = parseInt(
    backgroundHexColorCode[3] + backgroundHexColorCode[4],
    hexRadix,
  );
  const blue = parseInt(
    backgroundHexColorCode[5] + backgroundHexColorCode[6],
    hexRadix,
  );

  const backgroundRelativeLuminance = calculateRelativeLuminance(
    red,
    green,
    blue,
  );
  const contrastWithWhiteText = 1.05 / (backgroundRelativeLuminance + 0.05);
  const contrastWithBlackText = (backgroundRelativeLuminance + 0.05) / 0.05;

  return contrastWithBlackText >= contrastWithWhiteText ? '#000000' : '#FFFFFF';
};

const calculateRelativeLuminance = (
  red: number,
  green: number,
  blue: number,
): number => {
  const rgb = [red, green, blue];
  for (let i = 0; i < rgb.length; i++) {
    const normalizedColor = rgb[i] / 255;
    if (normalizedColor <= 0.04045) {
      rgb[i] = normalizedColor / 12.92;
    } else {
      rgb[i] = Math.pow((normalizedColor + 0.055) / 1.055, 2.4);
    }
  }

  return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
};
