<ion-toolbar *ngIf="viewModel$ | async as viewModel">
  <h2
    class="guest-product-compare-title"
    *ngIf="isGuestUser && isCurrentPageCompare"
  >
    {{ 'i18n.pageTitles.searchCompare' | translate }}
  </h2>
  <ion-buttons slot="start">
    <ng-container *ngIf="previousUrl">
      <ion-back-button
        *ngIf="
          ((orderService.restrictNavigationForCurrentOrder$ | async) !== true ||
            isCurrentPageCompare) &&
          (this.panAppState?.pageTitle$ | async) !== 'Home' &&
          (this.panAppState?.pageTitle$ | async) !== 'Inicio' &&
          (this.panAppState?.pageTitle$ | async) !== 'View All Lists' &&
          (this.panAppState?.pageTitle$ | async) !== 'Ver Todas las Listas' &&
          (this.panAppState?.pageTitle$ | async) !== 'My Orders' &&
          (this.panAppState?.pageTitle$ | async) !== 'Mis Pedidos' &&
          (this.panAppState?.pageTitle$ | async) !== 'Browse Products' &&
          (this.panAppState?.pageTitle$ | async) !== 'Explorar Productos' &&
          (this.panAppState?.pageTitle$ | async) !== 'Current Order' &&
          (this.panAppState?.pageTitle$ | async) !== 'Pedido Actual' &&
          (this.panAppState?.pageTitle$ | async) !== 'Deals' &&
          (this.panAppState?.pageTitle$ | async) !== 'Ofertas' &&
          (this.panAppState?.pageTitle$ | async) !== 'Profile' &&
          (this.panAppState?.pageTitle$ | async) !== 'Perfil' &&
          (this.panAppState?.pageTitle$ | async) !== 'Request Credit' &&
          (this.panAppState?.pageTitle$ | async) !== 'Solicitar Crédito' &&
          (this.panAppState?.pageTitle$ | async) !== 'Invoices' &&
          (this.panAppState?.pageTitle$ | async) !== 'Facturas' &&
          (this.panAppState?.pageTitle$ | async) !== 'Inventory' &&
          (this.panAppState?.pageTitle$ | async) !== 'Inventario' &&
          (this.panAppState?.pageTitle$ | async) !== 'Quick Order Entry' &&
          (this.panAppState?.pageTitle$ | async) !==
            'Entrada rápida de pedidos' &&
          !hideBackButton &&
          !isLocked
        "
        id="back-modal-btn"
        data-cy="header-back-button"
        text=""
        icon="chevron-back-outline"
        (click)="back(previousUrl)"
      ></ion-back-button>
    </ng-container>
    <div *ngIf="userCustomerInfo$ | async as userCustomerInfo">
      <ion-button
        *ngIf="
          (orderService.restrictNavigationForCurrentOrder$ | async) !== true &&
          !isGuestUser &&
          !isLocked
        "
        id="customer-selection-btn"
        data-cy="open-select-customer-header-button"
        class="customer-selection-btn"
        [disabled]="
          !userCustomerInfo.customers?.length ||
          (userCustomerInfo.customers?.length === 1 &&
            userCustomerInfo.customers[0].departments?.length < 2 &&
            !isSuperUser) ||
          isAlerts
        "
        mode="ios"
        (click)="openCustomerSelection()"
      >
        <ng-container *ngIf="(customerSwitchStatus$ | async) !== 'pending'">
          {{
            userCustomerInfo?.selectedCustomer?.customerName?.length > 10 &&
            userCustomerInfo.selectedCustomer?.departmentInd
              ? (userCustomerInfo?.selectedCustomer?.customerName
                  | titlecase
                  | slice: 0 : 10) + '...'
              : (userCustomerInfo?.selectedCustomer?.customerName | titlecase)
          }}
          ({{ userCustomerInfo?.selectedCustomer?.customerNumber }})
          {{
            !!userCustomerInfo.selectedDepartment?.departmentNumber &&
            !!userCustomerInfo.selectedDepartment?.departmentName
              ? userCustomerInfo.selectedDepartment?.departmentName?.length > 10
                ? (userCustomerInfo.selectedDepartment?.departmentName
                    | titlecase
                    | slice: 0 : 10) +
                  '...' +
                  ' (' +
                  userCustomerInfo.selectedDepartment?.departmentNumber +
                  ')'
                : (userCustomerInfo.selectedDepartment?.departmentName
                    | titlecase) +
                  ' (' +
                  userCustomerInfo.selectedDepartment?.departmentNumber +
                  ')'
              : ''
          }}
        </ng-container>
        <ng-container *ngIf="(customerSwitchStatus$ | async) === 'pending'">
          {{ 'i18n.header.loading' | translate
          }}<ion-spinner name="circles" class="loading-spinner"></ion-spinner>
        </ng-container>
      </ion-button>

      <ion-button
        *ngIf="
          (orderService.restrictNavigationForCurrentOrder$ | async) !== true &&
          isGuestUser &&
          !isCurrentPageCompare &&
          !isLocked
        "
        id="zip-code-change-btn"
        class="zip-code-change-btn"
        data-cy="zip-code-change-btn"
        mode="ios"
        (click)="openZipCodeChange()"
      >
        <ng-container>
          Zip Code:
          {{ (loginService.zipCode$ | async)?.toString() | zipCodeFormat }}
        </ng-container>
        <ion-icon
          src="assets/icon/pencil-green.svg"
          class="guest-pencil-icon"
        ></ion-icon>
      </ion-button>

      <div
        class="nav-restricted-customer"
        data-cy="nav-restricted-customer-label"
        *ngIf="
          (orderService.restrictNavigationForCurrentOrder$ | async) === true ||
          isLocked
        "
      >
        {{
          (userCustomerInfo$ | async)?.selectedCustomer?.customerName
            | titlecase
        }}
        ({{ (userCustomerInfo$ | async)?.selectedCustomer?.customerNumber }})
        {{
          (userCustomerInfo$ | async)?.selectedDepartment
            ? ((userCustomerInfo$ | async)?.selectedDepartment?.departmentName
                | titlecase) +
              ' (' +
              (userCustomerInfo$ | async)?.selectedDepartment
                ?.departmentNumber +
              ')'
            : ''
        }}
      </div>

      <div
        class="page-title"
        data-cy="header-home-text"
        *ngIf="!isCurrentPageCompare"
      >
        {{
          (this.panAppState?.pageTitle$ | async) || 'i18n.home.home' | translate
        }}
      </div>
    </div>
  </ion-buttons>

  <ion-buttons
    *ngIf="
      !(
        platformService?.platformType ===
          platformService?.platformEnum?.mobile && isInventory
      )
    "
    slot="end"
  >
    <ion-button
      *ngIf="isGuestUser && !isCurrentPageCompare && !isMobile"
      class="usf-fill-green-button become-a-customer-touch-header-button"
      data-cy="become-a-customer-touch-header-button"
      [href]="becomeACustomerUrl"
      target="_self"
    >
      <span>{{ 'i18n.common.becomeACustomer' | translate }}</span>
    </ion-button>
    <ion-button
      *ngIf="isGuestUser && !isCurrentPageCompare && !isMobile"
      class="usf-outline-green-button guest-login-touch-header-button"
      data-cy="guest-login-touch-header-button"
      (click)="logout()"
    >
      <span>{{ 'i18n.common.logIn' | translate }}</span>
    </ion-button>
    <ion-button
      *ngIf="
        (orderService.restrictNavigationForCurrentOrder$ | async) !== true &&
        !isCurrentPageCompare &&
        !isLocked
      "
      id="search-btn"
      data-cy="search-header-button"
      (click)="openSearchModal()"
      appHeaderAddShrinkAnimationOnClick
    >
      <ion-icon
        class="header-icon search-icon"
        name="search-outline"
      ></ion-icon>
    </ion-button>
    <ion-button
      *ngIf="
        (orderService.restrictNavigationForCurrentOrder$ | async) !== true &&
        !isGuestUser &&
        !isLocked
      "
      id="order-btn"
      data-cy="open-my-orders-header-button"
      (click)="routeToCart()"
    >
      <ion-icon class="header-icon cart-icon" name="cart"></ion-icon>
      <ng-container *ngIf="viewModel?.cartSyncing; else cartSynced">
        <ion-spinner
          class="cart-badge-header-spinner"
          color="warning"
          name="circular"
        ></ion-spinner
      ></ng-container>
      <ng-template #cartSynced>
        <ion-badge
          *ngIf="viewModel?.orderItemTallies?.totalItems > 0"
          [ngClass]="
            viewModel?.orderItemTallies?.totalItems < 10
              ? 'badge-single'
              : 'badge-multi'
          "
          data-cy="header-total-orders-count"
        >
          {{
            viewModel?.orderItemTallies?.totalItems > 999
              ? '999+'
              : viewModel?.orderItemTallies?.totalItems
          }}
        </ion-badge></ng-template
      >
    </ion-button>
  </ion-buttons>
  <ion-label
    slot="end"
    *ngIf="
      platformService?.platformType === platformService?.platformEnum?.mobile &&
      isInventory
    "
    class="inventory-total"
  >
    <p>
      {{ 'i18n.inventory.inventoryWorksheet.total' | translate }}
    </p>
    <p>
      {{ (inventoryValue ? inventoryValue : '0.00') | currency }}
    </p>
  </ion-label>
</ion-toolbar>

<app-message-bar
  [isMobile]="true"
  [shouldDisplay]="shouldDisplayMessageBar"
  [message]="messageBarText"
></app-message-bar>

<ion-modal
  *ngIf="isSuperUser"
  class="ion-modal-touch"
  [breakpoints]="[0, 1]"
  [initialBreakpoint]="1"
  [isOpen]="customerService.isSuperUserCustomersModalOpen$ | async"
  (ionModalDidDismiss)="customerService.setIsSuperUserCustomersModalOpen(false)"
>
  <ng-template>
    <app-super-user-customers-modal
      class="ion-page"
      [platform]="platformService.platformType"
      (isSuperUserCustomersModalOpenEmitter)="
        customerService.setIsSuperUserCustomersModalOpen(false)
      "
      (superUserCustomerSelectedEmitter)="setSuperUserSelectedCustomer($event)"
      (superUserCustomerFavoriteEmitter)="setSuperUserFavoriteCustomer($event)"
    >
    </app-super-user-customers-modal>
  </ng-template>
</ion-modal>
