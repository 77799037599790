export const DEFAULT_VISIBLE_FACET_ROWS = 10;

export const NEW_SEARCH_ERROR_MESSAGES = [
  'i18n.search.error.errorMessageBeginning',
  'i18n.search.error.errorMessageNoSearchTerm',
];

export const DELIVERY_TYPE_FACET = 'delivery_type';
export const US_FOODS_DELIVERED_VALUE = 'US Foods Delivered';
export const OUR_EXCLUSIVES_FACET = 'our_exclusives';
export const USF_PROGRAMS_FACET = 'usf_programs';
export const CATEGORY_FACET = 'ec_category';
export const BRAND_FACET = 'ec_brand';
export const ES_SEARCH_TERM = 'eandscoreessentials';

export const FACETS_TO_EXCLUDE_FROM_ACTION_BAR = [
  DELIVERY_TYPE_FACET,
  OUR_EXCLUSIVES_FACET,
];

export enum FACET_VALUE {
  ALL_SCOOP = 'All scoop',
  SERVE_YOU = 'Serve You',
}

export const FACETS_TYPE_TO_INCLUDE = ['regular', 'hierarchical'];
