import { Injectable } from '@angular/core';
import { PanAppState, PlatformService } from '@panamax/app-state';
import { HotKeys } from '@shared/constants/hot-key.enum';
import { ProductCardService } from '../product-card/product-card.service';
import { ProductService } from '../product/product.service';
import { firstValueFrom, combineLatest, filter } from 'rxjs';
import { IonInput, ModalController } from '@ionic/angular';
import { FEATURES } from '@shared/constants/splitio-features';

@Injectable({
  providedIn: 'root',
})
export class MerchHotkeyService {
  constructor(
    private readonly modalController: ModalController,
    private readonly platformService: PlatformService,
    private readonly productCardService: ProductCardService,
    private readonly productService: ProductService,
    private readonly panAppState: PanAppState,
  ) {}

  eventInitializator: IonInput;

  async onKeyEvent(
    event: KeyboardEvent,
    ionicElementCurrentlyFocused,
    originSearchPage?: string,
  ) {
    if (event.code === HotKeys.sellerQuickViewKey) {
      const [isFeatureEnabled, accessToken] = await firstValueFrom(
        combineLatest([
          this.panAppState.featureEnabled(
            FEATURES.split_global_seller_quick_view.name,
          ),
          this.panAppState.accessToken$,
        ]).pipe(
          filter(
            ([isFeatureEnabled, accessToken]) =>
              !!isFeatureEnabled && !!accessToken,
          ),
        ),
      );

      if (
        isFeatureEnabled &&
        this.productCardService.isInternalUser(accessToken)
      ) {
        if (
          ionicElementCurrentlyFocused &&
          this.isKeyWithinQtyBox(event, ionicElementCurrentlyFocused)
        ) {
          const productNumber = parseInt(
            ionicElementCurrentlyFocused.getAttribute('data-product-number'),
          );
          const product = await firstValueFrom(
            this.productService.getProduct(productNumber),
          );
          if (product) {
            this.eventInitializator = ionicElementCurrentlyFocused;
            this.productCardService.openQuickViewModal(
              {
                isTouch: this.platformService.isTouch.value,
                product,
              },
              'sellersQuickView',
              originSearchPage,
            );
          }
        } else {
          const modal = await this.modalController.getTop();
          if (modal && modal.id === 'usf-product-quick-view') {
            this.modalController
              .dismiss(undefined, undefined, 'usf-product-quick-view')
              .then(async () => {
                if (this.eventInitializator) {
                  (await this.eventInitializator.getInputElement()).focus();
                  this.eventInitializator = null;
                }
              });
          }
        }
      }
    }
  }

  isKeyWithinQtyBox(
    event: KeyboardEvent,
    ionicElementCurrentlyFocused: HTMLElement,
  ): boolean {
    const classes = ionicElementCurrentlyFocused.className;
    return (
      classes.includes('quantity-input-box') &&
      event.code === HotKeys.sellerQuickViewKey
    );
  }
}
