import { Injectable } from '@angular/core';
import {
  ListDetailViewModel,
  ListManagementViewModel,
  ProductRow,
} from '@app/lists/shared/list-detail-management/model/list-detail-management-view.model';
import { SelectedItem } from '@app/lists/shared/list-detail-management/model/selected-product.model';
import { ListDetailManagementService } from '@app/lists/shared/list-detail-management/services/list-detail-management.service';
import {
  ActionSheetController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { jwtDecode, PanAppState } from '@panamax/app-state';
import { PATHS } from '@shared/constants/paths';
import { ProductCompareEnum } from '@shared/constants/product-compare.enum';
import { FEATURES } from '@shared/constants/splitio-features';
import { ToggleNewSearchService } from '@shared/services/toggle-new-search/toggle-new-search.service';
import { ProductAnalyticsService } from '@usf/ngrx-product';
import { ProductPropertiesEnum, ProductSummary } from '@usf/product-types';
import { firstValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { ListsOverlayService } from '../../../lists/services/lists-overlay.service';
import { ProductDetailViewModel } from '../../../product-detail/models/product-detail-view-model';
import { ProductAlternativeSearchService } from '../../../product-detail/services/product-alternative-search.service';
import { ProductDetailTagsService } from '../../../product-detail/services/product-detail-tags.service';
import { productIsInStatus } from '../../../product-detail/utils/product-summary.utils';
import { EllipsisOptionsPopoverComponent } from '../../components/popovers/ellipsis-options/ellipsis-options-popover.component';
import { UsfProductQuickViewComponent } from '../../components/usf-product-quick-view/usf-product-quick-view.component';
import { ListActions, ListTypes } from '../../constants/lists-constants';
import { PdpLnkSrc } from '../../constants/pdp-lnksrc.enum';
import { UsfProductCardModeEnum } from '../../constants/usf-product-card-mode.enum';
import { NavigationHelperService } from '../../helpers/navigation.helpers.service';
import { Product } from '../../models/product.model';
import {
  EllipsisClickData,
  ProductQuickViewModel,
} from '../../models/usf-product-card.model';
import { ModalService } from '../modal/modal.service';

@Injectable({
  providedIn: 'root',
})
export class ProductCardService {
  constructor(
    private actionSheetController: ActionSheetController,
    public modalController: ModalController,
    private popoverController: PopoverController,
    readonly panAppState: PanAppState,
    public translateService: TranslateService,
    public listsOverlayService: ListsOverlayService,
    public modalService: ModalService,
    public navigationHelperService: NavigationHelperService,
    private productAlternativeSearchService: ProductAlternativeSearchService,
    private productDetailTagsService: ProductDetailTagsService,
    private listDetailManagementService: ListDetailManagementService,
    private analyticsService: ProductAnalyticsService,
    private readonly toggleNewSearchService: ToggleNewSearchService,
  ) {}

  async openProductCardEllipsis(buttons: any[], isTouch: boolean, event: any) {
    const ionHeader = document.getElementById('ionHeader');

    if (!isTouch) {
      const productOptionsPopover = await this.popoverController.create({
        component: EllipsisOptionsPopoverComponent,
        event: event,
        componentProps: {
          buttons,
        },
        cssClass: 'ellipsis-product-options-popover-desktop',
        mode: 'ios',
        showBackdrop: false,
      });
      if (!!ionHeader) {
        productOptionsPopover.onDidDismiss().then(() => {
          ionHeader.focus({
            preventScroll: true,
          });
        });
      }

      await productOptionsPopover.present();
    } else {
      buttons.push({
        text: this.translateService.instant('i18n.common.cancel'),
        role: 'cancel',
        cssClass: 'cancel-button',
      });
      const actionSheet = await this.actionSheetController.create({
        mode: 'ios',
        buttons,
        cssClass: 'action-sheet-touch',
      });
      if (!!ionHeader) {
        actionSheet.onDidDismiss().then(() => {
          ionHeader.focus({
            preventScroll: true,
          });
        });
      }
      await actionSheet.present();
    }
  }

  async dismissProductCardOptions(isTouch: boolean) {
    if (!isTouch) {
      await this.popoverController.dismiss();
    } else {
      await this.actionSheetController.dismiss();
    }
  }

  searchReplacement(
    productSummary: ProductSummary,
    isSubstitute?: boolean,
    originSearchPage?: string,
  ) {
    this.toggleNewSearchService
      .isNewSearchEnabled()
      .pipe(take(1))
      .subscribe(newSearchEnabled => {
        if (newSearchEnabled) {
          this.productAlternativeSearchService.newSearchReplacement(
            productSummary,
            isSubstitute,
          );
        } else {
          this.productAlternativeSearchService.searchReplacement(
            productSummary,
            UsfProductCardModeEnum.replacementSearch,
            false,
            null,
            originSearchPage,
          );
        }
      });
  }

  showProductStatuses(ellipsisClickData: EllipsisClickData) {
    let productDetail: ProductDetailViewModel;
    if (ellipsisClickData?.product?.hasAlternative) {
      productDetail = {
        ...ellipsisClickData?.product?.alternative?.product,
      } as ProductDetailViewModel;
    } else {
      productDetail = {
        ...ellipsisClickData?.product,
      } as ProductDetailViewModel;
    }
    this.productDetailTagsService.showAllAvailableStatuses(productDetail);
  }

  handleProductConversionClick(product: ProductRow, isListManagement: boolean) {
    if (
      isListManagement ||
      (product?.item?.forcedUpdate && !product?.betterBuy)
    ) {
      this.navigationHelperService.routeTo(PATHS.LIST_UPDATES);
    } else {
      this.navigationHelperService.routeTo(PATHS.BETTER_BUYS);
    }
  }

  async openQuickViewModal(
    ellipsisClickData: ProductQuickViewModel,
    selectedSegment: string,
    originSearchPage?: string,
  ) {
    const modal = await this.getQuickViewModal(
      ellipsisClickData,
      selectedSegment,
      originSearchPage,
    );

    const quickViewModal = await this.modalController.create(modal);

    this.analyticsService.trackUserOpenQuickViewModel(
      ellipsisClickData.product.summary.divisionNumber,
      ellipsisClickData.product.summary.productNumber,
      ellipsisClickData.product.trackingAttributes,
      ellipsisClickData.product?.inventory?.isInStock,
      ellipsisClickData.product?.hasAlternative,
    );

    quickViewModal.onDidDismiss().then((resp: any) => {
      this.analyticsService.trackUserCloseQuickViewModel(
        ellipsisClickData.product.summary.divisionNumber,
        ellipsisClickData.product.summary.productNumber,
        ellipsisClickData.product.trackingAttributes,
      );
      if (!!resp?.data) {
        this.routeToPDP(resp.data, true);
      }
    });
    return await quickViewModal.present();
  }

  routeToPDP(productNumber: number, isQuickView: boolean) {
    if (isQuickView) {
      this.navigationHelperService.routeToProductDetailsQueryParams(
        productNumber,
        { lnksrc: PdpLnkSrc.quickView },
      );
    } else {
      this.navigationHelperService.routeToProductDetails(productNumber);
    }
  }

  routeToPDPBetterBuyCard(productNumber: number, currentProduct: boolean) {
    this.navigationHelperService.routeToProductDetailsQueryParams(
      productNumber,
      {
        lnksrc: currentProduct ? 'betterBuysOriginal' : 'betterBuysSuggestion',
      },
    );
  }

  routeToProductCompare(
    originalItem: number,
    selectedProductNumbers: number[],
    mode: ProductCompareEnum,
  ) {
    this.navigationHelperService.routeTo(PATHS.SEARCH_COMPARE, {
      originalItem: originalItem,
      selectedProducts: selectedProductNumbers.join(','),
      mode: mode,
    });
  }

  shouldAddProductStatus(product: Product) {
    return (
      product?.productTags?.length > 0 ||
      product?.alternative?.product?.productTags?.length > 0 ||
      !!product?.drawerMsg?.message ||
      product?.summary?.properties?.has(
        ProductPropertiesEnum.onOrderGuideOrOnShoppingList,
      )
    );
  }

  shouldSeeNote(listTypeID: string = null) {
    return !(
      listTypeID === null ||
      listTypeID === ListTypes.og ||
      listTypeID === ListTypes.masterList ||
      listTypeID === ListTypes.recentlyPurchased
    );
  }

  shouldAddSearchForSubs(product: Product, listTypeId: string = null) {
    return (
      (this.allowReplacementSearch(product, listTypeId) ||
        product?.summary?.properties?.has(ProductPropertiesEnum.dwo) ||
        (productIsInStatus(['3', '4'], product) &&
          !product?.inventory?.isInStock) ||
        (productIsInStatus(['0', '2'], product) &&
          !product?.inventory?.isInStock) ||
        (product?.summary?.properties?.has(
          ProductPropertiesEnum.supplierOutOfStock,
        ) &&
          !product?.alternative?.prioritizeAlternative)) &&
      (product.allowSearchForSubs ?? true)
    );
  }

  allowReplacementSearch(product: Product, listTypeId: string) {
    if (!listTypeId) {
      return product?.summary?.properties?.has(
        ProductPropertiesEnum.discontinued,
      );
    } else {
      return (
        product?.summary?.properties?.has(ProductPropertiesEnum.discontinued) &&
        listTypeId !== ListTypes.og &&
        listTypeId !== ListTypes.recentlyPurchased &&
        listTypeId !== ListTypes.masterList
      );
    }
  }

  getQuickViewBtn(
    ellipsisClickData: EllipsisClickData,
    originSearchPage?: string,
  ) {
    return {
      text: this.translateService.instant(
        'i18n.productDetail.productQuickView',
      ),
      handler: () => {
        this.openQuickViewModal(
          ellipsisClickData,
          'quickView',
          originSearchPage,
        );
        this.dismissProductCardOptions(ellipsisClickData.isTouch);
      },
    };
  }

  getSearchForSubsBtn(
    ellipsisClickData: EllipsisClickData,
    originSearchPage?: string,
  ) {
    const text = ellipsisClickData?.product?.summary?.properties?.has(
      ProductPropertiesEnum.discontinued,
    )
      ? 'i18n.lists.searchForReplacements'
      : 'i18n.lists.searchForSubs';

    return {
      text: this.translateService.instant(text),
      handler: () => {
        this.dismissProductCardOptions(ellipsisClickData.isTouch);
        this.searchReplacement(
          ellipsisClickData.product?.summary,
          !ellipsisClickData?.product?.summary?.properties?.has(
            ProductPropertiesEnum.discontinued,
          ),
          originSearchPage,
        );
      },
    };
  }

  getProductStatusBtn(ellipsisClickData: EllipsisClickData) {
    return {
      text: this.translateService.instant(
        'i18n.productDetail.productStatusInformation',
      ),
      handler: () => {
        this.dismissProductCardOptions(ellipsisClickData.isTouch);
        this.openQuickViewModal(ellipsisClickData, 'productStatus');
      },
    };
  }

  getDeleteProductBtn(
    ellipsisClickData: EllipsisClickData,
    viewModel: ListDetailViewModel | ListManagementViewModel,
  ) {
    const selectedProducts: SelectedItem[] = [
      {
        product: ellipsisClickData.product.summary,
        item: ellipsisClickData.product.item,
      },
    ];

    const currentGroup = viewModel.listGroups?.find(group => {
      return group.groupName === ellipsisClickData.product.groupName;
    });
    return {
      text: this.translateService.instant('i18n.lists.deleteProduct'),
      handler: async () => {
        const copyAcrossListsFlag = await this.panAppState.featureEnabled(
          FEATURES.split_global_copy_products_across_lists.name,
        );

        this.dismissProductCardOptions(ellipsisClickData.isTouch);
        const ret = await this.listsOverlayService.openSelectedProductsModal(
          ListActions.delete,
          selectedProducts,
          viewModel.list,
          viewModel.listGroups,
          currentGroup,
          viewModel.listItems,
          copyAcrossListsFlag,
          true,
        );

        ret.onDidDismiss().then(data => {
          let modalData = data['data'];
          if (modalData?.selectedGroup) {
            this.listDetailManagementService.deleteProducts(
              viewModel.list,
              modalData.selectedGroup,
              selectedProducts,
            );
          }
        });
      },
    };
  }

  getRecentlyPurchasedDeleteProductBtn = (
    ellipsisClickData: EllipsisClickData,
  ) => {
    return {
      text: this.translateService.instant('i18n.lists.deleteProduct'),
      handler: async () => {
        this.dismissProductCardOptions(ellipsisClickData.isTouch);
        this.listsOverlayService.openRecentlyPurchasedDeleteModal(
          ellipsisClickData.product.recentPurchase,
        );
      },
    };
  };

  async getQuickViewModal(
    ellipsisClickData: ProductQuickViewModel,
    selectedSegment: string,
    originSearchPage?: string,
  ) {
    const isDynamicLastFourBuysLookbackEnabled =
      await this.panAppState.featureEnabled(
        FEATURES.split_dynamic_seller_lfb_lookback.name,
      );

    return this.modalService.setModalOptions(
      ellipsisClickData.isTouch,
      UsfProductQuickViewComponent,
      {
        ellipsisClickData,
        originSearchPage,
        selectedSegment,
        isDynamicLastFourBuysLookbackEnabled,
      },
      'modal-desktop-size-xl',
      false,
      undefined,
      undefined,
      'usf-product-quick-view',
    );
  }

  async getCompareBtn(buttons, ellipsisClickData: EllipsisClickData) {
    await this.panAppState
      .featureEnabled(FEATURES.split_global_product_compare_split_card.name)
      .then(on => {
        if (
          on &&
          ellipsisClickData?.product?.hasAlternative &&
          (this.shouldAddAcceptReplacement(
            ellipsisClickData.product,
            this.getListTypeId(ellipsisClickData),
          ) ||
            this.shouldAddSearchForSubs(ellipsisClickData.product) ||
            ellipsisClickData?.product?.isMslRestricted)
        ) {
          this.addCompareButtonToEllipsis(buttons, ellipsisClickData);
        }
      });
  }

  addCompareButtonToEllipsis(buttons, ellipsisClickData: EllipsisClickData) {
    const button = {
      text: this.translateService.instant(
        'i18n.search.searchCompare.compareFromEllipsis',
      ),
      handler: () => {
        this.dismissProductCardOptions(ellipsisClickData.isTouch);
        //check replacement to add mode

        let compareMode = null;
        switch (ellipsisClickData.mode) {
          case UsfProductCardModeEnum.reviewOrder:
            compareMode = ProductCompareEnum.reviewOrder;
            break;
          case UsfProductCardModeEnum.listDetail:
            if (
              this.allowReplacementSearch(
                ellipsisClickData.product,
                this.getListTypeId(ellipsisClickData),
              )
            ) {
              compareMode = ProductCompareEnum.replacementListsCompare;
            } else {
              compareMode = ProductCompareEnum.listsDetailCompare;
            }
            break;
          case UsfProductCardModeEnum.didYouForget:
            compareMode = ProductCompareEnum.didYouForgetCompare;
            break;
          case UsfProductCardModeEnum.topSeller:
            compareMode = ProductCompareEnum.topSellerCompare;
            break;
          case UsfProductCardModeEnum.trendingNow:
            compareMode = ProductCompareEnum.trendingNowCompare;
            break;
          default:
            if (
              this.allowReplacementSearch(
                ellipsisClickData.product,
                this.getListTypeId(ellipsisClickData),
              )
            ) {
              compareMode = ProductCompareEnum.replacementCompare;
            }
            break;
        }

        this.productAlternativeSearchService.trackUserProductCompareEllipsisMenu(
          ellipsisClickData?.product,
        );

        this.navigationHelperService.routeTo(
          PATHS.SEARCH_COMPARE,
          {
            selectedProducts: `${ellipsisClickData?.product?.productNumber}, ${ellipsisClickData?.product?.alternative?.product?.productNumber}`,
            originalItem: ellipsisClickData?.product?.productNumber,
            mode: compareMode,
          },
          {
            list: { ...ellipsisClickData.list },
          },
        );
      },
    };
    buttons.push(button);
  }

  shouldAddAcceptReplacement(product: Product, listTypeID: string) {
    return (
      ((product?.summary?.properties?.has(ProductPropertiesEnum.discontinued) &&
        listTypeID !== ListTypes.og &&
        listTypeID !== ListTypes.recentlyPurchased) ||
        (product?.summary?.properties?.has(ProductPropertiesEnum.dwo) &&
          product?.summary?.properties?.has(
            ProductPropertiesEnum.supplierOutOfStock,
          ))) &&
      product?.hasAlternative
    );
  }

  getListTypeId(ellipsisClickData: EllipsisClickData): string {
    let listType = '';

    if (ellipsisClickData.list) {
      listType = ellipsisClickData.list?.listKey?.listTypeId;
    } else if (ellipsisClickData.mode == 'recentlyPurchased') {
      listType = ListTypes.recentlyPurchased;
    } else if (!!ellipsisClickData.masterList) {
      listType = ListTypes.masterList;
    }

    return listType;
  }

  isInternalUser(token: string) {
    return jwtDecode(token)?.['usf-claims']?.userType === 'corp-ad';
  }

  getSellerQuickViewBtn(buttons, ellipsisClickData: EllipsisClickData) {
    Promise.all([
      this.panAppState.featureEnabled(
        FEATURES.split_global_seller_quick_view.name,
      ),
      firstValueFrom(this.panAppState.accessToken$),
    ]).then(([flagIsOn, token]) => {
      if (flagIsOn && this.isInternalUser(token)) {
        const button = {
          text: this.translateService.instant(
            'i18n.productDetail.sellersQuickView',
          ),
          handler: () => {
            this.openQuickViewModal(ellipsisClickData, 'sellersQuickView');
            this.dismissProductCardOptions(ellipsisClickData.isTouch);
          },
        };
        buttons.push(button);
      }
    });
  }
}
