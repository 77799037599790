import { FeatureFlag } from '@panamax/app-state/lib/pan-app-state-lib.config';

export const FEATURES: Record<string, FeatureFlag> = {
  'split_dynamic_esj_test': { name: 'split_dynamic_esj_test', control: false },
  'split_global_tandem_product_inventory': {
    name: 'split_global_tandem_product_inventory',
    control: false,
  },
  'split_test_esj_flag': { name: 'split_test_esj_flag', control: false },
  'Test_Flag': { name: 'Test_Flag', control: false },
  'split_user_payments_ar_statements': {
    name: 'split_user_payments_ar_statements',
    control: true,
  },
  'split_global_ai_list': { name: 'split_global_ai_list', control: false },
  'split_global_seller_showcase_products': {
    name: 'split_global_seller_showcase_products',
    control: false,
  },
  'split_global_import_order_multi_thread_list_product_call': {
    name: 'split_global_import_order_multi_thread_list_product_call',
    control: false,
  },
  'split_global_import_order_list_product_call': {
    name: 'split_global_import_order_list_product_call',
    control: false,
  },
  'split_customer_mpp': { name: 'split_customer_mpp', control: false },
  'split_global_usda_nutritional_panel': {
    name: 'split_global_usda_nutritional_panel',
    control: false,
  },
  'split_division_available_to_promise': {
    name: 'split_division_available_to_promise',
    control: true,
  },
  'split_global_order_status_filter_defaults': {
    name: 'split_global_order_status_filter_defaults',
    control: false,
  },
  'split_global_order_reviewed': {
    name: 'split_global_order_reviewed',
    control: false,
  },
  'split_global_mpp': { name: 'split_global_mpp', control: false },
  'split_user_alerts_upc_warehouse_cuts': {
    name: 'split_user_alerts_upc_warehouse_cuts',
    control: true,
  },
  'split_dynamic_order_image_ordering': {
    name: 'split_dynamic_order_image_ordering',
    control: false,
  },
  'split_global_sous_status': {
    name: 'split_global_sous_status',
    control: false,
  },
  'split_global_order_hide_eta': {
    name: 'split_global_order_hide_eta',
    control: false,
  },
  'split_global_cpr_override_common_component': {
    name: 'split_global_cpr_override_common_component',
    control: false,
  },
  'split_user_mpp': { name: 'split_user_mpp', control: false },
  'split_global_browser_agent': {
    name: 'split_global_browser_agent',
    control: false,
  },
  'split_global_seller_quick_view': {
    name: 'split_global_seller_quick_view',
    control: false,
  },
  'split_global_manage_users': {
    name: 'split_global_manage_users',
    control: false,
  },
  'split_global_price_cache_control': {
    name: 'split_global_price_cache_control',
    control: true,
  },
  'split_user_app_reviews': { name: 'split_user_app_reviews', control: false },
  'split_global_download_list_nutritionals': {
    name: 'split_global_download_list_nutritionals',
    control: true,
  },
  'split_user_payments_ar_statements_new_badge': {
    name: 'split_user_payments_ar_statements_new_badge',
    control: true,
  },
  'split_global_product_inventory_metrics': {
    name: 'split_global_product_inventory_metrics',
    control: false,
  },
  'split_global_redirect_unauthenticated_users_to_guest': {
    name: 'split_global_redirect_unauthenticated_users_to_guest',
    control: true,
  },
  'split_global_blizzard_deals': {
    name: 'split_global_blizzard_deals',
    control: false,
  },
  'split_global_download_list_dual_pane': {
    name: 'split_global_download_list_dual_pane',
    control: true,
  },
  'split_user_order_gen_ai_playground': {
    name: 'split_user_order_gen_ai_playground',
    control: false,
  },
  'split_global_order_gen_ai_playground': {
    name: 'split_global_order_gen_ai_playground',
    control: false,
  },
  'split_global_import_order_split_card': {
    name: 'split_global_import_order_split_card',
    control: true,
  },
  'split_global_support_request': {
    name: 'split_global_support_request',
    control: false,
  },
  'split_global_guest_experience': {
    name: 'split_global_guest_experience',
    control: true,
  },
  'split_global_cpr_last_puchase': {
    name: 'split_global_cpr_last_puchase',
    control: true,
  },
  'split_global_super_user_favorite_customer': {
    name: 'split_global_super_user_favorite_customer',
    control: false,
  },
  'split_global_aog_iframe': { name: 'split_global_aog_iframe', control: true },
  'split_user_payments_share_payment_confirmation': {
    name: 'split_user_payments_share_payment_confirmation',
    control: true,
  },
  'split_global_order_confirmation_share': {
    name: 'split_global_order_confirmation_share',
    control: true,
  },
  'split_global_order_details_share': {
    name: 'split_global_order_details_share',
    control: true,
  },
  'split_global_get_localized_content': {
    name: 'split_global_get_localized_content',
    control: false,
  },
  'split_customization_delivery_toggle_preset': {
    name: 'split_customization_delivery_toggle_preset',
    control: false,
  },
  'split_global_order_uqe_revised': {
    name: 'split_global_order_uqe_revised',
    control: false,
  },
  'split_global_import_non_usf_products': {
    name: 'split_global_import_non_usf_products',
    control: true,
  },
  'split_global_alerts_meta_conversions_api': {
    name: 'split_global_alerts_meta_conversions_api',
    control: true,
  },
  'split_global_alerts_survey': {
    name: 'split_global_alerts_survey',
    control: true,
  },
  'split_global_search_new_search_analytics': {
    name: 'split_global_search_new_search_analytics',
    control: false,
  },
  'split_user_payments_payment_activity': {
    name: 'split_user_payments_payment_activity',
    control: true,
  },
  'split_user_payments_dashboard': {
    name: 'split_user_payments_dashboard',
    control: true,
  },
  'split_global_order_super_user_import_order': {
    name: 'split_global_order_super_user_import_order',
    control: true,
  },
  'split_global_order_review_share': {
    name: 'split_global_order_review_share',
    control: true,
  },
  'split_global_msl_compare_alternates': {
    name: 'split_global_msl_compare_alternates',
    control: true,
  },
  'split_user_order_sync_process': {
    name: 'split_user_order_sync_process',
    control: false,
  },
  'split_user_payments_dashboard_override': {
    name: 'split_user_payments_dashboard_override',
    control: false,
  },
  'split_global_tablet_aog': { name: 'split_global_tablet_aog', control: true },
  'split_global_alerts_user_mgmt_premier': {
    name: 'split_global_alerts_user_mgmt_premier',
    control: true,
  },
  'split_global_alerts_user_mgmt_adv_opts': {
    name: 'split_global_alerts_user_mgmt_adv_opts',
    control: true,
  },
  'split_global_search_banner_crossvalleyfarms': {
    name: 'split_global_search_banner_crossvalleyfarms',
    control: true,
  },
  'split_global_order_edit_cart': {
    name: 'split_global_order_edit_cart',
    control: true,
  },
  'split_global_offline_update_quantities': {
    name: 'split_global_offline_update_quantities',
    control: true,
  },
  'split_global_call_order_submission_api': {
    name: 'split_global_call_order_submission_api',
    control: true,
  },
  'split_global_msl_slim_product_load': {
    name: 'split_global_msl_slim_product_load',
    control: true,
  },
  'split_user_alerts_upc_direct': {
    name: 'split_user_alerts_upc_direct',
    control: true,
  },
  'split_global_alerts_upc_warehouse_cuts': {
    name: 'split_global_alerts_upc_warehouse_cuts',
    control: true,
  },
  'split_global_aog': { name: 'split_global_aog', control: true },
  'split_global_direct_allow_returns': {
    name: 'split_global_direct_allow_returns',
    control: true,
  },
  'split_global_search_banner_equip_and_supplies': {
    name: 'split_global_search_banner_equip_and_supplies',
    control: true,
  },
  'split_division_order_pronto': {
    name: 'split_division_order_pronto',
    control: false,
  },
  'split_global_product_compare_share': {
    name: 'split_global_product_compare_share',
    control: true,
  },
  'split_global_more_from_this_brand': {
    name: 'split_global_more_from_this_brand',
    control: true,
  },
  'split_user_toggle_new_search': {
    name: 'split_user_toggle_new_search',
    control: false,
  },
  'split_global_forced_update_notification': {
    name: 'split_global_forced_update_notification',
    control: true,
  },
  'split_global_avendraforgood_banner': {
    name: 'split_global_avendraforgood_banner',
    control: false,
  },
  'split_global_order_msk_topic': {
    name: 'split_global_order_msk_topic',
    control: false,
  },
  'split_global_will_call_type': {
    name: 'split_global_will_call_type',
    control: true,
  },
  'split_division_order_shipto_button': {
    name: 'split_division_order_shipto_button',
    control: true,
  },
  'split_division_order_recovery_button': {
    name: 'split_division_order_recovery_button',
    control: false,
  },
  'split_global_search_banner_EBCOP': {
    name: 'split_global_search_banner_EBCOP',
    control: true,
  },
  'split_global_contracts_v2': {
    name: 'split_global_contracts_v2',
    control: false,
  },
  'split_global_dyf_fbt_filter': {
    name: 'split_global_dyf_fbt_filter',
    control: true,
  },
  'split_global_product_compare_download': {
    name: 'split_global_product_compare_download',
    control: true,
  },
  'split_global_cab_v2': { name: 'split_global_cab_v2', control: true },
  'split_global_order_tpo_decompose_flag': {
    name: 'split_global_order_tpo_decompose_flag',
    control: true,
  },
  'split_global_support_center': {
    name: 'split_global_support_center',
    control: true,
  },
  'split_global_product_compare_print': {
    name: 'split_global_product_compare_print',
    control: true,
  },
  'split_global_order_add_lost_line': {
    name: 'split_global_order_add_lost_line',
    control: true,
  },
  'split_user_payments_allInvoices_download': {
    name: 'split_user_payments_allInvoices_download',
    control: true,
  },
  'split_global_seller_scoop_preview': {
    name: 'split_global_seller_scoop_preview',
    control: false,
  },
  'split_global_search_within_order_details': {
    name: 'split_global_search_within_order_details',
    control: true,
  },
  'split_global_search_banner_payments': {
    name: 'split_global_search_banner_payments',
    control: true,
  },
  'split_global_alerts_user_mgmt': {
    name: 'split_global_alerts_user_mgmt',
    control: true,
  },
  'split_global_ngp_cpr_low_limit': {
    name: 'split_global_ngp_cpr_low_limit',
    control: true,
  },
  'split_global_search_within_review_order': {
    name: 'split_global_search_within_review_order',
    control: true,
  },
  'split_global_quick_order_entry_cust_prod': {
    name: 'split_global_quick_order_entry_cust_prod',
    control: true,
  },
  'split_global_search_banner_revman': {
    name: 'split_global_search_banner_revman',
    control: true,
  },
  'split_division_local_only_invite_user': {
    name: 'split_division_local_only_invite_user',
    control: false,
  },
  'split_global_invite_new_user': {
    name: 'split_global_invite_new_user',
    control: true,
  },
  'split_global_alerts_sfmc_deliv_excep': {
    name: 'split_global_alerts_sfmc_deliv_excep',
    control: true,
  },
  'split_global_PFAS_restriction': {
    name: 'split_global_PFAS_restriction',
    control: false,
  },
  'split_global_auth_api_user_last_login_tracking': {
    name: 'split_global_auth_api_user_last_login_tracking',
    control: true,
  },
  'split_user_payments_payment_preferences': {
    name: 'split_user_payments_payment_preferences',
    control: true,
  },
  'split_global_download_search': {
    name: 'split_global_download_search',
    control: true,
  },
  'split_global_vs_order_status_display': {
    name: 'split_global_vs_order_status_display',
    control: true,
  },
  'split_user_vs_order_status_display': {
    name: 'split_user_vs_order_status_display',
    control: true,
  },
  'split_global_vs_special_instructions_cust_default': {
    name: 'split_global_vs_special_instructions_cust_default',
    control: true,
  },
  'split_global_order_detail_refresher': {
    name: 'split_global_order_detail_refresher',
    control: true,
  },
  'split_global_order_delivery_instructions': {
    name: 'split_global_order_delivery_instructions',
    control: true,
  },
  'split_global_topsellernull_recentpurchase_filter': {
    name: 'split_global_topsellernull_recentpurchase_filter',
    control: false,
  },
  'split_global_food_cost_report': {
    name: 'split_global_food_cost_report',
    control: true,
  },
  'split_global_force_app_update': {
    name: 'split_global_force_app_update',
    control: false,
  },
  'split_user_vs_special_instructions': {
    name: 'split_user_vs_special_instructions',
    control: true,
  },
  'split_global_price_analytics': {
    name: 'split_global_price_analytics',
    control: true,
  },
  'split_global_mass_delete_list': {
    name: 'split_global_mass_delete_list',
    control: true,
  },
  'split_global_vs_special_instructions': {
    name: 'split_global_vs_special_instructions',
    control: true,
  },
  'split_global_alerts_upc_direct': {
    name: 'split_global_alerts_upc_direct',
    control: true,
  },
  'split_global_order_status_download': {
    name: 'split_global_order_status_download',
    control: true,
  },
  'split_global_ws_enabled': {
    name: 'split_global_ws_enabled',
    control: false,
  },
  'split_global_review_order_frequently_bought_together': {
    name: 'split_global_review_order_frequently_bought_together',
    control: true,
  },
  'split_user_payments_autopay': {
    name: 'split_user_payments_autopay',
    control: false,
  },
  'split_global_order_status_refresher': {
    name: 'split_global_order_status_refresher',
    control: true,
  },
  'split_global_alerts_notifications_dlq': {
    name: 'split_global_alerts_notifications_dlq',
    control: false,
  },
  'split_global_alerts_sfmc_order_emails': {
    name: 'split_global_alerts_sfmc_order_emails',
    control: true,
  },
  'split_global_my_orders_refresher': {
    name: 'split_global_my_orders_refresher',
    control: true,
  },
  'split_global_assign_pim_nonUsfProduct': {
    name: 'split_global_assign_pim_nonUsfProduct',
    control: true,
  },
  'split_global_my_orders_call': {
    name: 'split_global_my_orders_call',
    control: false,
  },
  'split_global_myorder_recent_order_call': {
    name: 'split_global_myorder_recent_order_call',
    control: true,
  },
  'split_global_add_multiple_products': {
    name: 'split_global_add_multiple_products',
    control: true,
  },
  'split_global_alerts_sfmc_tm_notes': {
    name: 'split_global_alerts_sfmc_tm_notes',
    control: true,
  },
  'split_global_alerts_sfmc_truck': {
    name: 'split_global_alerts_sfmc_truck',
    control: true,
  },
  'split_global_alerts_sfmc_profile': {
    name: 'split_global_alerts_sfmc_profile',
    control: true,
  },
  'split_global_alerts_sfmc_order_cutoff': {
    name: 'split_global_alerts_sfmc_order_cutoff',
    control: true,
  },
  'split_global_alerts_sfmc_deliv_headsup': {
    name: 'split_global_alerts_sfmc_deliv_headsup',
    control: true,
  },
  'split_global_alerts_sfmc_pay_due': {
    name: 'split_global_alerts_sfmc_pay_due',
    control: true,
  },
  'split_global_payments_outage': {
    name: 'split_global_payments_outage',
    control: false,
  },
  'split_global_call_request_reply_api': {
    name: 'split_global_call_request_reply_api',
    control: true,
  },
  'split_division_alerts_phone_nbrs': {
    name: 'split_division_alerts_phone_nbrs',
    control: true,
  },
  'split_global_alerts_phone_nbrs': {
    name: 'split_global_alerts_phone_nbrs',
    control: true,
  },
  'split_division_ia_pod': { name: 'split_division_ia_pod', control: true },
  'split_global_ia_pod': { name: 'split_global_ia_pod', control: true },
  'split_customer_type_not_NA_invite_new_user': {
    name: 'split_customer_type_not_NA_invite_new_user',
    control: true,
  },
  'split_global_review_order_merch_zone': {
    name: 'split_global_review_order_merch_zone',
    control: true,
  },
  'split_global_modify_vs_order': {
    name: 'split_global_modify_vs_order',
    control: true,
  },
  'split_global_download_imported_orders': {
    name: 'split_global_download_imported_orders',
    control: true,
  },
  'split_global_import_order_submit_key': {
    name: 'split_global_import_order_submit_key',
    control: false,
  },
  'split_global_call_import_order_submission_api': {
    name: 'split_global_call_import_order_submission_api',
    control: false,
  },
  'split_global_order_insight': {
    name: 'split_global_order_insight',
    control: true,
  },
  'split_global_order_wmt_from_mongo': {
    name: 'split_global_order_wmt_from_mongo',
    control: true,
  },
  'split_global_better_buys': {
    name: 'split_global_better_buys',
    control: true,
  },
  'split_global_inventory_gl_sorting': {
    name: 'split_global_inventory_gl_sorting',
    control: true,
  },
  'split_division_invite_new_user': {
    name: 'split_division_invite_new_user',
    control: true,
  },
  'split_customization_direct_separate_shipping': {
    name: 'split_customization_direct_separate_shipping',
    control: false,
  },
  'split_global_alerts_sfmc_order_status': {
    name: 'split_global_alerts_sfmc_order_status',
    control: true,
  },
  'split_global_alerts_sfmc_direct': {
    name: 'split_global_alerts_sfmc_direct',
    control: true,
  },
  'split_global_alerts_sfmc_payment': {
    name: 'split_global_alerts_sfmc_payment',
    control: true,
  },
  'split_global_alerts_super_user': {
    name: 'split_global_alerts_super_user',
    control: true,
  },
  'split_global_alerts_delete_user': {
    name: 'split_global_alerts_delete_user',
    control: true,
  },
  'split_global_direct_separate_shipping': {
    name: 'split_global_direct_separate_shipping',
    control: true,
  },
  'split_global_product_price_per_portion_card_flag': {
    name: 'split_global_product_price_per_portion_card_flag',
    control: true,
  },
  'split_global_primary_language_selection': {
    name: 'split_global_primary_language_selection',
    control: true,
  },
  'split_customer_type_not_NA_appcues': {
    name: 'split_customer_type_not_NA_appcues',
    control: true,
  },
  'split_division_alerts_appcues': {
    name: 'split_division_alerts_appcues',
    control: true,
  },
  'split_global_quick_order_entry_page': {
    name: 'split_global_quick_order_entry_page',
    control: true,
  },
  'split_global_pickup_request': {
    name: 'split_global_pickup_request',
    control: true,
  },
  'split_global_inventory_migration': {
    name: 'split_global_inventory_migration',
    control: true,
  },
  'split_global_copy_list': { name: 'split_global_copy_list', control: true },
  'split_global_wmt_tile_order_source': {
    name: 'split_global_wmt_tile_order_source',
    control: true,
  },
  'split_user_order_view_model_selector': {
    name: 'split_user_order_view_model_selector',
    control: false,
  },
  'split_global_order_view_model_selector': {
    name: 'split_global_order_view_model_selector',
    control: false,
  },
  'split_global_works_well_with_flag': {
    name: 'split_global_works_well_with_flag',
    control: true,
  },
  'split_global_payments_bank_validation': {
    name: 'split_global_payments_bank_validation',
    control: true,
  },
  'split_user_payments_make_a_payment': {
    name: 'split_user_payments_make_a_payment',
    control: true,
  },
  'split_user_payments_cancel_payment': {
    name: 'split_user_payments_cancel_payment',
    control: true,
  },
  'split_global_mass_copy_list': {
    name: 'split_global_mass_copy_list',
    control: true,
  },
  'split_global_recovery_annotate_moxe_order': {
    name: 'split_global_recovery_annotate_moxe_order',
    control: false,
  },
  'split_global_chef_store_role_check': {
    name: 'split_global_chef_store_role_check',
    control: true,
  },
  'split_global_review_order_download': {
    name: 'split_global_review_order_download',
    control: true,
  },
  'split_global_import_list': {
    name: 'split_global_import_list',
    control: true,
  },
  'split_global_quick_order_entry_widget': {
    name: 'split_global_quick_order_entry_widget',
    control: true,
  },
  'split_global_order_unusual_quantities': {
    name: 'split_global_order_unusual_quantities',
    control: true,
  },
  'split_global_avendra_toggle': {
    name: 'split_global_avendra_toggle',
    control: false,
  },
  'split_global_alerts_one_time_direct': {
    name: 'split_global_alerts_one_time_direct',
    control: false,
  },
  'split_global_inventory_cost_comparison_report': {
    name: 'split_global_inventory_cost_comparison_report',
    control: true,
  },
  'split_division_payments_make_a_payment': {
    name: 'split_division_payments_make_a_payment',
    control: true,
  },
  'split_global_imported_orders': {
    name: 'split_global_imported_orders',
    control: true,
  },
  'split_global_alerts_trendview': {
    name: 'split_global_alerts_trendview',
    control: true,
  },
  'split_global_inventory_extended_cost_report': {
    name: 'split_global_inventory_extended_cost_report',
    control: true,
  },
  'split_global_recovery_general': {
    name: 'split_global_recovery_general',
    control: false,
  },
  'split_auth_api_moxe_maintenance_mode': {
    name: 'split_auth_api_moxe_maintenance_mode',
    control: false,
  },
  'split_global_inventory_worksheet_download': {
    name: 'split_global_inventory_worksheet_download',
    control: true,
  },
  'split_global_print_pdp': { name: 'split_global_print_pdp', control: true },
  'split_division_recently_viewed': {
    name: 'split_division_recently_viewed',
    control: true,
  },
  'split_global_share_pdp_desktop': {
    name: 'split_global_share_pdp_desktop',
    control: true,
  },
  'split_division_alerts_qr_code': {
    name: 'split_division_alerts_qr_code',
    control: true,
  },
  'split_user_payments_link': {
    name: 'split_user_payments_link',
    control: true,
  },
  'split_global_background_refresh': {
    name: 'split_global_background_refresh',
    control: false,
  },
  'split_global_list_download': {
    name: 'split_global_list_download',
    control: true,
  },
  'split_global_import_order': {
    name: 'split_global_import_order',
    control: true,
  },
  'split_global_review_order_print': {
    name: 'split_global_review_order_print',
    control: true,
  },
  'split_turn_off_data_sync': {
    name: 'split_turn_off_data_sync',
    control: false,
  },
  'split_global_order_confirmation_print': {
    name: 'split_global_order_confirmation_print',
    control: true,
  },
  'split_global_list_item_note': {
    name: 'split_global_list_item_note',
    control: true,
  },
  'split_global_past_orders_page': {
    name: 'split_global_past_orders_page',
    control: true,
  },
  'split_global_order_details_print': {
    name: 'split_global_order_details_print',
    control: true,
  },
  'split_global_alerts_wmt_refresh': {
    name: 'split_global_alerts_wmt_refresh',
    control: false,
  },
  'split_global_recently_viewed': {
    name: 'split_global_recently_viewed',
    control: true,
  },
  'split_global_appcues': { name: 'split_global_appcues', control: true },
  'split_global_create_new_order_option': {
    name: 'split_global_create_new_order_option',
    control: true,
  },
  'split_global_user_customizations': {
    name: 'split_global_user_customizations',
    control: true,
  },
  'split_user_order_sync_process_test': {
    name: 'split_user_order_sync_process_test',
    control: false,
  },
  'split_global_product_compare_popover': {
    name: 'split_global_product_compare_popover',
    control: true,
  },
  'split_global_product_compare_split_card': {
    name: 'split_global_product_compare_split_card',
    control: true,
  },
  'split_global_news_page_customizations': {
    name: 'split_global_news_page_customizations',
    control: true,
  },
  'split_global_autosub_review_order': {
    name: 'split_global_autosub_review_order',
    control: true,
  },
  'split_global_new_inventory_feature': {
    name: 'split_global_new_inventory_feature',
    control: true,
  },
  'split_global_synchronous_pricing': {
    name: 'split_global_synchronous_pricing',
    control: true,
  },
  'split_global_order_sync_process_test': {
    name: 'split_global_order_sync_process_test',
    control: false,
  },
  'split_global_user_customizations_logo_new': {
    name: 'split_global_user_customizations_logo_new',
    control: false,
  },
  'split_global_user_customizations_logo_default': {
    name: 'split_global_user_customizations_logo_default',
    control: false,
  },
  'split_global_order_confirmation_download': {
    name: 'split_global_order_confirmation_download',
    control: true,
  },
  'split_global_mychanges_watermark': {
    name: 'split_global_mychanges_watermark',
    control: false,
  },
  'split_global_alerts_custom_message': {
    name: 'split_global_alerts_custom_message',
    control: true,
  },
  'split_user_websocket_reconnect': {
    name: 'split_user_websocket_reconnect',
    control: false,
  },
  'split_global_user_app_error_modal': {
    name: 'split_global_user_app_error_modal',
    control: true,
  },
  'split_user_inventory': { name: 'split_user_inventory', control: false },
  'split_global_confluent_cloud': {
    name: 'split_global_confluent_cloud',
    control: false,
  },
  'split_global_cpr_button': { name: 'split_global_cpr_button', control: true },
  'split_global_chef_store_qr_code_new': {
    name: 'split_global_chef_store_qr_code_new',
    control: false,
  },
  'split_global_chef_store_qr_code': {
    name: 'split_global_chef_store_qr_code',
    control: true,
  },
  'split_global_cpr_pricing_service': {
    name: 'split_global_cpr_pricing_service',
    control: true,
  },
  'split_global_product_compare': {
    name: 'split_global_product_compare',
    control: true,
  },
  'split_global_profile_redirect': {
    name: 'split_global_profile_redirect',
    control: false,
  },
  'split_global_logout_all_sessions': {
    name: 'split_global_logout_all_sessions',
    control: false,
  },
  'split_global_interact_banners': {
    name: 'split_global_interact_banners',
    control: true,
  },
  'split_global_delivery_toggle': {
    name: 'split_global_delivery_toggle',
    control: true,
  },
  'split_global_alerts_one_time_pymt_push': {
    name: 'split_global_alerts_one_time_pymt_push',
    control: false,
  },
  'split_global_order_guide_filter': {
    name: 'split_global_order_guide_filter',
    control: true,
  },
  'split_global_min_order_met': {
    name: 'split_global_min_order_met',
    control: true,
  },
  'split_global_order_details_download': {
    name: 'split_global_order_details_download',
    control: true,
  },
  'split_global_share_pdp': { name: 'split_global_share_pdp', control: true },
  'split_global_alerts_wmt_will_call': {
    name: 'split_global_alerts_wmt_will_call',
    control: false,
  },
  'split_global_alerts_customer_switch': {
    name: 'split_global_alerts_customer_switch',
    control: false,
  },
  'split_global_alerts_payment_push': {
    name: 'split_global_alerts_payment_push',
    control: false,
  },
  'split_global_download_print': {
    name: 'split_global_download_print',
    control: true,
  },
  'split_global_master_lists': {
    name: 'split_global_master_lists',
    control: true,
  },
  'split_global_login_refactor': {
    name: 'split_global_login_refactor',
    control: true,
  },
  'split_global_analytics': { name: 'split_global_analytics', control: true },
  'split_global_og_msl_subs': {
    name: 'split_global_og_msl_subs',
    control: true,
  },
  'split_global_inspired_pick_products': {
    name: 'split_global_inspired_pick_products',
    control: true,
  },
  'split_global_dyf_products': {
    name: 'split_global_dyf_products',
    control: true,
  },
  'split_global_national_customers': {
    name: 'split_global_national_customers',
    control: false,
  },
  'split_global_direct_shipping_savings': {
    name: 'split_global_direct_shipping_savings',
    control: true,
  },
  'split_global_alerts_upc_redirect': {
    name: 'split_global_alerts_upc_redirect',
    control: false,
  },
  'split_global_inventory': { name: 'split_global_inventory', control: true },
  'split_b2c_refreshtoken_call': {
    name: 'split_b2c_refreshtoken_call',
    control: false,
  },
  'split_global_external_vendors': {
    name: 'split_global_external_vendors',
    control: false,
  },
  'split_global_pdp_redesign': {
    name: 'split_global_pdp_redesign',
    control: false,
  },
  'split_division_dfg_banner': {
    name: 'split_division_dfg_banner',
    control: false,
  },
  'split_global_service_request': {
    name: 'split_global_service_request',
    control: true,
  },
  'split_global_deals': { name: 'split_global_deals', control: true },
  'split_global_logoutrocket_sessions': {
    name: 'split_global_logoutrocket_sessions',
    control: true,
  },
  'split_global_kibo_tag': { name: 'split_global_kibo_tag', control: true },
  'split_global_moxe_links': { name: 'split_global_moxe_links', control: true },
  'split_global_alerts_tm_notes': {
    name: 'split_global_alerts_tm_notes',
    control: true,
  },
  'split_global_profile_page': {
    name: 'split_global_profile_page',
    control: true,
  },
  'split_global_deeplinking': {
    name: 'split_global_deeplinking',
    control: true,
  },
  'split_global_product_reviews': {
    name: 'split_global_product_reviews',
    control: true,
  },
  'split_global_product_questions': {
    name: 'split_global_product_questions',
    control: true,
  },
  'split_global_convert_to_willcall': {
    name: 'split_global_convert_to_willcall',
    control: true,
  },
  'split_global_push_notifications': {
    name: 'split_global_push_notifications',
    control: true,
  },
  'split_global_microsoft_login_automation': {
    name: 'split_global_microsoft_login_automation',
    control: false,
  },
  'split_global_price_per_portion': {
    name: 'split_global_price_per_portion',
    control: true,
  },
  'split_global_create_opportunity': {
    name: 'split_global_create_opportunity',
    control: true,
  },
  'split_global_microsoft_login_sellers_customers': {
    name: 'split_global_microsoft_login_sellers_customers',
    control: false,
  },
  'split_global_alerts_promote_app': {
    name: 'split_global_alerts_promote_app',
    control: true,
  },
  'split_global_microsoft_login': {
    name: 'split_global_microsoft_login',
    control: true,
  },
  'split_global_legacy_login': {
    name: 'split_global_legacy_login',
    control: false,
  },
  'legacy_login': { name: 'legacy_login', control: false },
  'demo_customer_traffic_type_feature2': {
    name: 'demo_customer_traffic_type_feature2',
    control: false,
  },
  'test_split': { name: 'test_split', control: false },
  'demo_customer_filter_iporders_wo_po': {
    name: 'demo_customer_filter_iporders_wo_po',
    control: false,
  },
  'demo_customer_traffic_type_feature': {
    name: 'demo_customer_traffic_type_feature',
    control: false,
  },
  'demo_division_traffic_type_feature2': {
    name: 'demo_division_traffic_type_feature2',
    control: false,
  },
  'demo_division_traffic_type_feature': {
    name: 'demo_division_traffic_type_feature',
    control: false,
  },
  'demo_user_traffic_type_feature3': {
    name: 'demo_user_traffic_type_feature3',
    control: false,
  },
  'demo_user_trafic_type_feature2': {
    name: 'demo_user_trafic_type_feature2',
    control: false,
  },
  'demo_user_trafic_type_feature': {
    name: 'demo_user_trafic_type_feature',
    control: false,
  },
  'split_global_home_scoop_banner': {
    name: 'split_global_home_scoop_banner',
    control: true,
  },
  'split_global_download': { name: 'split_global_download', control: true },
  'split_global_mychanges_sync': {
    name: 'split_global_mychanges_sync',
    control: true,
  },
  'split_global_product_card_updates': {
    name: 'split_global_product_card_updates',
    control: true,
  },
  '\nsplit_global_sync_flag': {
    name: '\nsplit_global_sync_flag',
    control: false,
  },
  'split_user_internal_service_request': {
    name: 'split_user_internal_service_request',
    control: false,
  },
  'split_global_inventory_cost_report': {
    name: 'split_global_inventory_cost_report',
    control: true,
  },
  'split_global_copy_products_across_lists': {
    name: 'split_global_copy_products_across_lists',
    control: true,
  },
  'split_global_equipment_and_supplies': {
    name: 'split_global_equipment_and_supplies',
    control: true,
  },
  'split_global_search_banner_whilesupplieslast': {
    name: 'split_global_search_banner_whilesupplieslast',
    control: true,
  },
  'split_global_search_banner_serveyou': {
    name: 'split_global_search_banner_serveyou',
    control: true,
  },
  'split_global_search_browse': {
    name: 'split_global_search_browse',
    control: true,
  },
  'split_user_new_search_enabled': {
    name: 'split_user_new_search_enabled',
    control: false,
  },
  'split_dynamic_EandS_promo_banner': {
    name: 'split_dynamic_EandS_promo_banner',
    control: false,
  },
  'split_dynamic_business_analytics': {
    name: 'split_dynamic_business_analytics',
    control: false,
  },
  'split_dynamic_proprietary_ui': {
    name: 'split_dynamic_proprietary_ui',
    control: false,
  },
  'split_dynamic_default_po': {
    name: 'split_dynamic_default_po',
    control: true,
  },
  'split_dynamic_seller_lfb_lookback': {
    name: 'split_dynamic_seller_lfb_lookback',
    control: false,
  },
  'split_dynamic_hide_market_change': {
    name: 'split_dynamic_hide_market_change',
    control: false,
  },
  'split_dynamic_rp_delete': {
    name: 'split_dynamic_rp_delete',
    control: true,
  },
};
