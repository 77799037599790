import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PanAppState, PlatformService } from '@panamax/app-state';
import { TooltipService } from '../../popovers/tooltip/tooltip.service';
import { UsfProductCardInfoBaseComponent } from '../usf-product-card-info-base.component';
import { FEATURES } from '@shared/constants/splitio-features';
import { ListsService } from '@app/lists/services/lists.service';
import { ListsOverlayService } from '@app/lists/services/lists-overlay.service';
import { ProductShippingPriceService } from '@shared/services/product-shipping-price/product-shipping-price.service';
import { Observable } from 'rxjs';
import { OrderService } from '@order/services/order.service';
import { OrderItem } from '@usf/ngrx-order';
import { AddToListModalService } from '@shared/services/dual-pane/add-to-list-modal.service';

@Component({
  selector: 'app-usf-product-card-info-touch',
  templateUrl: './usf-product-card-info-touch.component.html',
  styleUrls: [
    '../usf-product-card-info-base.component.scss',
    './usf-product-card-info-touch.component.scss',
  ],
})
export class UsfProductCardInfoTouchComponent
  extends UsfProductCardInfoBaseComponent
  implements OnInit, OnChanges
{
  constructor(
    platformService: PlatformService,
    panAppState: PanAppState,
    tooltipService: TooltipService,
    listsService: ListsService,
    listsOverlayService: ListsOverlayService,
    private productShippingPriceService: ProductShippingPriceService,
    readonly orderService: OrderService,
    readonly addToListModalService: AddToListModalService,
  ) {
    super(
      platformService,
      panAppState,
      tooltipService,
      listsService,
      listsOverlayService,
    );
  }

  orderItem$: Observable<OrderItem>;

  isProductCardNumSectionExpanded = false;
  numToShow: string;
  numToShowTrimmed: string;
  numToHide: string;
  numToHideTrimmed: string;
  isProductShippingPriceEnabled$: Observable<boolean>;

  readonly split_global_product_price_per_portion_card_flag =
    FEATURES.split_global_product_price_per_portion_card_flag.name;

  ngOnInit() {
    this.isProductShippingPriceEnabled$ =
      this.productShippingPriceService.isProductShippingPriceEnabled$();
    if (this.useOrderObservable === true) {
      this.orderItem$ = this.orderService.getOrderItemWithProductNumber$(
        this.product?.productNumber,
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.product?.customerProductNumber) {
      if (this.product.isGLCustomer) {
        this.numToShow = 'Prod #' + this.product.productNumber.toString();
        this.numToHide = this.product.customerProductNumber;
      } else {
        this.numToShow = this.product.customerProductNumber;
        this.numToHide = 'Prod #' + this.product.productNumber.toString();
      }
      this.numToShowTrimmed = this.numToShow.replace(/\s/g, '').toLowerCase();
      this.numToHideTrimmed = this.numToHide.replace(/\s/g, '').toLowerCase();
    }
  }

  setProductCardNumSectionExpanded() {
    this.isProductCardNumSectionExpanded = this.isProductCardNumSectionExpanded
      ? false
      : true;
  }

  setSelectedNumberToShow(event: any) {
    if (event?.target?.innerText) {
      this.numToShow = event.target.innerText;
      this.numToShowTrimmed = this.numToShow.replace(/\s/g, '').toLowerCase();
      this.numToHide =
        this.numToShow === this.product.customerProductNumber
          ? 'Prod #' + this.product.productNumber.toString()
          : this.product.customerProductNumber;
    }
    this.numToHideTrimmed = this.numToHide.replace(/\s/g, '').toLowerCase();
  }

  async openAddToListModal() {
    this.product.merchFeatureName = this.merchFeature;
    this.product.resultRank = this.carouselPosition;
    this.addToListModalService.openModalViaController(
      [this.product],
      false,
      false,
      this.addMethod,
      this.merchFeature,
    );
  }
}
