import { MyOrdersService } from '@app/my-orders/services/my-orders.service';
import { createSelector } from '@ngrx/store';
import {
  PlatformEnum,
  SelectedCustomerState,
  UserState,
  selectAllFeaturesEnabled,
} from '@panamax/app-state';
import { Customer } from '@usf/customer-types';
import {
  IServiceRequestState,
  selectServiceRequestState,
} from '@usf/ngrx-list';
import {
  IMPORT_ORDERS_SELECTORS,
  IMPORTED_ORDERS_FILTERS_SELECTORS,
  ImportOrderCheck,
  Order,
  ORDER_REVIEW_SELECTORS,
  OrderHeader,
  orderSelectors,
  ordersFiltersSelectors,
  OrderStatus,
  partnerSelectors,
} from '@usf/ngrx-order';
import { OrderState } from '@usf/ngrx-order';
import moment from 'moment';

import {
  getCustomers,
  selectAppStateSelectedCustomer,
  selectAppStateUser,
} from 'src/app/ngrx-customer/store';
import { QuickFilter } from 'src/app/shared/components/usf-filtering-banner/model/quick-filter.model';
import {
  DeliveryMethodAbbreviation,
  DisplayType,
  OrderStatusDetails,
} from 'src/app/shared/constants/order-status-title.enum';
import { FEATURES } from 'src/app/shared/constants/splitio-features';
import {
  DateRow,
  MyOrdersFilterCounts,
  MyOrdersQuickFilters,
  MyOrdersRowType,
  MyOrdersViewModel,
  NoOrderRow,
  OrderRow,
  CustomerOrdersCounts,
  CustomerOrdersViewModel,
  TitleRow,
  PastOrdersRow,
  MyOrdersTallies,
  ImportedOrdersViewModel,
  ImportedOrdersCounts,
  ImportedOrdersQuickFilters,
} from 'src/app/shared/models/my-orders-view.model';
import { OrderHeaderService } from '../services/order-header.service';
import { OrderReviewedService } from '../services/order-reviewed.service';
import { PartnerState } from '@usf/ngrx-order/lib/models/state/partner-state';
import { ImportedOrdersFiltersState } from '@usf/ngrx-order/lib/models/state/imported-orders-filters-state';
import { selectUserCustomizations } from '@app/user/store/selectors';
import { selectUserKind } from '@app/user/store';
import { UserKinds } from '@usf/user-types/user';
import { SiteCustomizationProfile } from '@usf/user-types/site-customization';
import { PUNCHOUT_PO_PENDING } from '@shared/constants/order-punchout';
import { OrderReviewPreferences } from '@usf/order-types';

export const selectRequestCreditOrders = (keyword: string) =>
  createSelector(
    selectAppStateSelectedCustomer,
    orderSelectors.selectOrderContextState,
    selectServiceRequestState,
    (
      selectedCustomerState: SelectedCustomerState,
      orderHeaderState: OrderState,
      serviceRequest: IServiceRequestState,
    ): { myOrders: Order[]; ordersLoaded: boolean } => {
      const wmtOrderNumbers =
        OrderHeaderService.getListOfWMTOrders(orderHeaderState);

      let myOrders: Order[] = (orderHeaderState.ids as Array<string | number>)
        .map((id: string | number) => {
          const order = orderHeaderState?.entities[id];
          // Order is current customer's
          const orderBelongsToCustomer =
            order?.orderHeader?.customerNumber ===
            selectedCustomerState?.customerNumber;

          // Order is WMT and has status DELIVERED
          if (wmtOrderNumbers.has(order?.orderHeader?.tandemOrderNumber)) {
            if (order?.orderHeader?.orderDelivered ?? false) {
              OrderStatusDetails[order?.orderHeader?.orderStatus] =
                OrderStatusDetails[OrderStatus.DELIVERED];
            }
          }

          // Order has status SHIPPED, SHIPPED_WITH_EXCEPTIONS, TANDEM_DELETED, or DELIVERED
          const orderStatusShippedOrDelivered =
            order?.orderHeader?.orderStatus === OrderStatus.SHIPPED ||
            order?.orderHeader?.orderStatus ===
              OrderStatus.SHIPPED_WITH_EXCEPTIONS ||
            order?.orderHeader?.orderStatus === OrderStatus.TANDEM_DELETED ||
            order?.orderHeader?.orderStatus === OrderStatus.DELIVERED;

          // Order has order type RT, VS, or WC
          const orderTypeRTorVS =
            order?.orderHeader?.orderType === 'RT' ||
            order?.orderHeader?.orderType === 'VS' ||
            order?.orderHeader?.orderType === 'WC';

          // Order delivery date is w/in the order history days set in Service Request data
          const orderHistoryDays = serviceRequest?.serviceRequest
            ?.orderHistoryDays
            ? serviceRequest.serviceRequest.orderHistoryDays
            : 3; // Default to 3 if orderHistoryDays is not provided

          // Make sure the confirmed delivery date is in the correct format and is a Moment object
          const confirmedDeliveryDate = moment
            .utc(order.orderHeader.confirmedDeliveryDate)
            .utcOffset(0);

          // Start from the current date and subtract Business days
          let lastOrderHistoryDays = moment.utc().utcOffset(0);

          // Subtract Business days (Monday to Friday)
          let businessDaysCount = 0;
          while (businessDaysCount < orderHistoryDays) {
            lastOrderHistoryDays = lastOrderHistoryDays.subtract(1, 'days'); // Subtract one day

            // Check if the day is a Business day (Monday to Friday)
            if (
              lastOrderHistoryDays.day() !== 0 &&
              lastOrderHistoryDays.day() !== 6
            ) {
              businessDaysCount++;
            }
          }

          // Format the final date
          const formattedLastOrderHistoryDays =
            lastOrderHistoryDays.format('MM/DD/YYYY');

          // Compare if the order was made within the last `orderHistoryDays` Business days
          const orderInLast3BusinessDays = lastOrderHistoryDays.isBefore(
            confirmedDeliveryDate,
          );

          // return true if date has not happened yet:
          const orderInFuture = isDeliveryDateInFuture(
            confirmedDeliveryDate.format('MM/DD/YYYY'),
          );

          // order object is not empty:
          const orderEmpty = orderIsEmpty(order);

          if (
            orderBelongsToCustomer &&
            orderStatusShippedOrDelivered &&
            orderTypeRTorVS &&
            orderInLast3BusinessDays &&
            !orderInFuture &&
            !orderEmpty
          ) {
            return order;
          }
        })
        .filter(order => {
          if (order) {
            return order;
          }
        })
        .sort((a: Order, b: Order) => {
          return (
            new Date(b.orderHeader?.confirmedDeliveryDate).getTime() -
            new Date(a.orderHeader?.confirmedDeliveryDate).getTime()
          );
        });

      if (keyword && keyword.length > 0) {
        myOrders = myOrders.filter(order =>
          order?.orderHeader?.tandemOrderNumber.toString().includes(keyword),
        );
      }
      return {
        myOrders,
        ordersLoaded:
          orderHeaderState.ready &&
          !orderHeaderState.recentOrdersLoading &&
          !orderHeaderState.customerOrdersLoading,
      };
    },
  );

export const selectMyOrdersViewModel = (platformType: PlatformEnum) =>
  createSelector(
    selectAppStateUser,
    selectAppStateSelectedCustomer,
    orderSelectors.selectOrderContextState,
    ordersFiltersSelectors.selectOrdersFilters,
    getCustomers, // there should be a customerSelector.getCUstomersState
    selectAllFeaturesEnabled([FEATURES.split_global_national_customers.name]),
    selectAllFeaturesEnabled([FEATURES.split_global_modify_vs_order.name]),
    selectOrderStatusDisplayEnabled(),
    partnerSelectors.selectPartner,
    selectUserCustomizations,
    ORDER_REVIEW_SELECTORS.selectAllOrderReviewPreferences,
    selectUserKind,
    (
      loggedInUser: UserState,
      selectedCustomer: SelectedCustomerState,
      orderHeaderState: OrderState,
      filters: any,
      customers: Customer[],
      restrictedNationalFlagEnabled: boolean,
      modifyVSOrdersEnabled: boolean,
      orderStatusDisplayEnabled: boolean,
      partnerState: PartnerState,
      siteCustomizations,
      reviewedOrders: OrderReviewPreferences[],
      userKind: UserKinds,
    ): MyOrdersViewModel => {
      const listWmtOrderNumber =
        OrderHeaderService.getListOfWMTOrders(orderHeaderState);
      const defaultFilterDays = loggedInUser?.tmUser === 'Y' ? 1 : 21;
      const defaultFilterStart = new Date();
      const defaultFilterEnd = new Date(defaultFilterStart);
      defaultFilterEnd.setDate(defaultFilterEnd.getDate() + defaultFilterDays);

      const defaultDateFilter = {
        deliveryDate: {
          start: `${
            defaultFilterStart.getMonth() + 1
          }/${defaultFilterStart.getDate()}/${defaultFilterStart.getFullYear()}`,
          end: `${
            defaultFilterEnd.getMonth() + 1
          }/${defaultFilterEnd.getDate()}/${defaultFilterEnd.getFullYear()}`,
        },
      };

      const customerDictionary = {};
      // get the state so that we can access it by customer number instead of creating this dictionary
      customers.map(c => {
        customerDictionary[c.customerNumber] = c;
      });

      const customersWithOrdersByDate = new Map<string, Set<number>>();

      const orderRows: OrderRow[] = Object.values(orderHeaderState.entities)
        .filter((order: Order) => {
          if (
            !OrderStatusDetails[order.orderHeader.orderStatus]?.title ||
            order.orderHeader.orderStatus === OrderStatus.DELETED ||
            order.orderHeader.orderStatus === OrderStatus.CANCELLED
          ) {
            return false;
          }
          const today = moment().startOf('day').format('YYYY-MM-DD');
          const deliveryDate = moment
            .utc(order.orderHeader?.confirmedDeliveryDate)
            .startOf('date')
            .format('YYYY-MM-DDTHH:mm');
          if (
            !!order.orderHeader?.confirmedDeliveryDate &&
            deliveryDate < today
          ) {
            return false;
          }
          if (order.orderHeader?.confirmedDeliveryDate) {
            const dateKey = MyOrdersService.getDateKey(
              new Date(order.orderHeader?.confirmedDeliveryDate),
            );
            const customerList =
              customersWithOrdersByDate.get(dateKey) || new Set<number>();
            customerList.add(order.orderHeader.customerNumber);
            customersWithOrdersByDate.set(dateKey, customerList);
          }

          return true;
        })
        .filter(
          (order: Order) =>
            order.orderHeader.orderStatus !== OrderStatus.ORDER_CANCELLED ||
            orderStatusDisplayEnabled,
        )
        .map((order: Order) => {
          const orderRow: OrderRow = buildOrderRow(
            order,
            listWmtOrderNumber,
            customerDictionary,
            loggedInUser,
            selectedCustomer,
            restrictedNationalFlagEnabled,
            false,
            partnerState,
            siteCustomizations,
            modifyVSOrdersEnabled,
            orderStatusDisplayEnabled,
            userKind,
            reviewedOrders,
          );

          return orderRow;
        });
      const noOrderRows: NoOrderRow[] = customers
        .filter(customer => {
          if (MyOrdersService.customerHasRecentOrder(customer)) {
            const nextDeliveryDate =
              MyOrdersService.getNextDeliveryDate(customer);
            if (nextDeliveryDate) {
              const dateKey = MyOrdersService.getDateKey(nextDeliveryDate);

              if (
                customersWithOrdersByDate.has(dateKey) &&
                customersWithOrdersByDate
                  .get(dateKey)
                  .has(customer.customerNumber)
              ) {
                return false;
              }
            }
          } else {
            return false;
          }
          return true;
        })
        .map(customer => {
          const nextDeliveryDate =
            MyOrdersService.getNextDeliveryDate(customer);
          const departmentNumber = customer.departmentNumbers?.length
            ? customer.departmentNumbers[0]
            : 0;
          return {
            customer,
            deliveryDate: nextDeliveryDate,
            currentCustomerInState: selectedCustomer,
            rowType: MyOrdersRowType.NoOrderRow,
            displayType: DisplayType.NO_ORDER,
            orderHeader: {
              orderStatus: OrderStatus.NO_ORDER,
              customerNumber: customer.customerNumber,
              departmentNumber,
              confirmedDeliveryDate: nextDeliveryDate,
            } as OrderHeader,
          } as NoOrderRow;
        });

      const filterCounts: MyOrdersFilterCounts = {
        submittedCount: 0,
        submittedWithExceptionsCount: 0,
        myDeliveriesCount: 0,
        openOrdersCount: 0,
      };
      const myOrdersRows: (OrderRow | NoOrderRow)[] = [
        ...orderRows,
        ...noOrderRows,
      ]
        .filter(row => {
          if (!filters.filterSelected) {
            return true;
          }
          const filtersNotIncludingStatus = {
            deliveryDate: filters.filters.deliveryDate,
            customers: filters.filters.customers,
            deliveryMethod: filters.filters.deliveryMethod,
          };
          return filterOrders(filtersNotIncludingStatus, row.orderHeader);
        })
        .filter(row => {
          switch (row.orderHeader.orderStatus) {
            case OrderStatus.SHIPPED:
              filterCounts.myDeliveriesCount++;
              break;
            case OrderStatus.INVOICE:
              if (row.orderHeader.orderType === 'VS')
                filterCounts.myDeliveriesCount++;
              break;
            case OrderStatus.DELIVERED:
              filterCounts.myDeliveriesCount++;
              break;
            case OrderStatus.NO_ORDER:
              filterCounts.openOrdersCount++;
              break;
            case OrderStatus.IN_PROGRESS:
              filterCounts.openOrdersCount++;
              break;
            case OrderStatus.SUBMITTED:
              filterCounts.submittedCount++;
              break;
            case OrderStatus.SUBMITTED_CREDIT_HOLD:
              filterCounts.submittedCount++;
              break;
            case OrderStatus.SUBMITTED_WITH_EXCEPTIONS:
              filterCounts.submittedWithExceptionsCount++;
              break;
            default:
              break;
          }
          if (!filters.filterSelected) {
            return true;
          }

          return filterOrders(filters.filters, row.orderHeader);
        })
        .sort((a, b) => {
          return sortOrderRows(a, b, true);
        });
      const myOrders: MyOrdersViewModel = buildMyOrdersVM(
        myOrdersRows,
        customers,
        filters,
        platformType,
        defaultDateFilter,
        filterCounts,
        reviewedOrders,
        userKind,
      );

      return myOrders;
    },
  );

export const selectOrderStatusOrders = () =>
  createSelector(
    selectAppStateUser,
    orderSelectors.selectOrderContextState,
    ordersFiltersSelectors.selectOrdersFilters,
    getCustomers,
    ORDER_REVIEW_SELECTORS.selectAllOrderReviewPreferences,
    selectUserKind,
    (
      loggedInUser: UserState,
      orderHeaderState: OrderState,
      filters: any,
      customers: Customer[],
      reviewedOrders: OrderReviewPreferences[],
      userKind: UserKinds,
    ): Order[] => {
      const defaultFilterDays = loggedInUser?.tmUser === 'Y' ? 1 : 21;
      const defaultFilterStart = new Date();
      const defaultFilterEnd = new Date(defaultFilterStart);
      defaultFilterEnd.setDate(defaultFilterEnd.getDate() + defaultFilterDays);

      const customerDictionary = {};
      customers.map(c => {
        customerDictionary[c.customerNumber] = c;
      });

      const customersWithOrdersByDate = new Map<string, Set<number>>();

      const orders: Order[] = Object.values(orderHeaderState.entities).filter(
        (order: Order) => {
          if (
            !OrderStatusDetails[order.orderHeader.orderStatus]?.title ||
            order.orderHeader.orderStatus === OrderStatus.DELETED ||
            order.orderHeader.orderStatus === OrderStatus.CANCELLED
          ) {
            return false;
          }
          const today = moment().startOf('day').format('YYYY-MM-DD');
          const deliveryDate = moment
            .utc(order.orderHeader?.confirmedDeliveryDate)
            .startOf('date')
            .format('YYYY-MM-DDTHH:mm');
          if (
            !!order.orderHeader?.confirmedDeliveryDate &&
            deliveryDate < today
          ) {
            return false;
          }
          if (order.orderHeader?.confirmedDeliveryDate) {
            const dateKey = MyOrdersService.getDateKey(
              new Date(order.orderHeader?.confirmedDeliveryDate),
            );
            const customerList =
              customersWithOrdersByDate.get(dateKey) || new Set<number>();
            customerList.add(order.orderHeader.customerNumber);
            customersWithOrdersByDate.set(dateKey, customerList);
          }

          return true;
        },
      );

      const noOrders: Order[] = customers
        .filter(customer => {
          if (MyOrdersService.customerHasRecentOrder(customer)) {
            const nextDeliveryDate =
              MyOrdersService.getNextDeliveryDate(customer);
            if (nextDeliveryDate) {
              const dateKey = MyOrdersService.getDateKey(nextDeliveryDate);

              if (
                customersWithOrdersByDate.has(dateKey) &&
                customersWithOrdersByDate
                  .get(dateKey)
                  .has(customer.customerNumber)
              ) {
                return false;
              }
            }
          } else {
            return false;
          }
          return true;
        })
        .map(customer => {
          const nextDeliveryDate =
            MyOrdersService.getNextDeliveryDate(customer);
          const departmentNumber = customer.departmentNumbers?.length
            ? customer.departmentNumbers[0]
            : 0;
          return {
            orderHeader: {
              orderStatus: OrderStatus.NO_ORDER,
              customerNumber: customer.customerNumber,
              departmentNumber,
              confirmedDeliveryDate: nextDeliveryDate,
            } as OrderHeader,
          } as Order;
        });

      const filteredOrders: Order[] = [...orders, ...noOrders]
        .filter(row => {
          if (!filters.filterSelected) {
            return true;
          }
          const filtersNotIncludingStatus = {
            deliveryDate: filters.filters.deliveryDate,
            customers: filters.filters.customers,
            deliveryMethod: filters.filters.deliveryMethod,
          };
          return filterOrders(filtersNotIncludingStatus, row.orderHeader);
        })
        .filter(row => {
          if (!filters.filterSelected) {
            return true;
          }

          return filterOrders(filters.filters, row.orderHeader);
        })
        .sort((orderA, orderB) => {
          return sortOrders(
            orderA,
            orderB,
            customerDictionary[orderA.orderHeader.customerNumber],
            customerDictionary[orderB.orderHeader.customerNumber],
          );
        });

      return filteredOrders;
    },
  );

export const selectOrderStatusDisplayEnabled = () =>
  createSelector(
    selectAllFeaturesEnabled([
      FEATURES.split_global_vs_order_status_display.name,
    ]),
    selectAllFeaturesEnabled([
      FEATURES.split_user_vs_order_status_display.name,
    ]),
    (
      globalOrderStatusDisplayEnabled: boolean,
      userOrderStatusDisplayEnabled: boolean,
    ): boolean => {
      return globalOrderStatusDisplayEnabled || userOrderStatusDisplayEnabled;
    },
  );

export const selectCustomerOrdersViewModel = (platformType: PlatformEnum) =>
  createSelector(
    selectAppStateUser,
    selectAppStateSelectedCustomer,
    orderSelectors.selectOrderContextState,
    getCustomers,
    selectAllFeaturesEnabled([FEATURES.split_global_national_customers.name]),
    selectAllFeaturesEnabled([FEATURES.split_global_modify_vs_order.name]),
    selectOrderStatusDisplayEnabled(),
    partnerSelectors.selectPartner,
    selectUserCustomizations,
    ORDER_REVIEW_SELECTORS.selectAllOrderReviewPreferences,
    selectUserKind,
    (
      loggedInUser: UserState,
      selectedCustomer: SelectedCustomerState,
      orderHeaderState: OrderState,
      customers: Customer[],
      restrictedNationalFlagEnabled: boolean,
      modifyVSOrdersEnabled: boolean,
      orderStatusDisplayEnabled: boolean,
      partnerState: PartnerState,
      siteCustomizations,
      reviewedOrders: OrderReviewPreferences[],
      userKind: UserKinds,
    ): CustomerOrdersViewModel => {
      const listWmtOrderNumber =
        OrderHeaderService.getListOfWMTOrders(orderHeaderState);

      const customerDictionary = {};
      // get the state so that we can access it by customer number instead of creating this dictionary
      customers.map(c => {
        customerDictionary[c.customerNumber] = c;
      });

      const submittedOrderRows: OrderRow[] = getCustomerOrdersRows(
        orderHeaderState,
        selectedCustomer,
        listWmtOrderNumber,
        customerDictionary,
        loggedInUser,
        restrictedNationalFlagEnabled,
        true,
        false,
        partnerState,
        siteCustomizations,
        modifyVSOrdersEnabled,
        orderStatusDisplayEnabled,
        reviewedOrders,
        userKind,
      );

      const inProgressOrderRows: OrderRow[] = getCustomerOrdersRows(
        orderHeaderState,
        selectedCustomer,
        listWmtOrderNumber,
        customerDictionary,
        loggedInUser,
        restrictedNationalFlagEnabled,
        false,
        false,
        partnerState,
        siteCustomizations,
        modifyVSOrdersEnabled,
        orderStatusDisplayEnabled,
        reviewedOrders,
        userKind,
      );

      const pastOrdersRows: OrderRow[] = getCustomerOrdersRows(
        orderHeaderState,
        selectedCustomer,
        listWmtOrderNumber,
        customerDictionary,
        loggedInUser,
        restrictedNationalFlagEnabled,
        false,
        true,
        partnerState,
        siteCustomizations,
        modifyVSOrdersEnabled,
        orderStatusDisplayEnabled,
        reviewedOrders,
        userKind,
      );

      const customerOrdersCounts: CustomerOrdersCounts = {
        submittedCount: submittedOrderRows.length,
        openOrdersCount: inProgressOrderRows.length,
        totalCount: submittedOrderRows.length + inProgressOrderRows.length,
      };

      const myOrders: CustomerOrdersViewModel = buildCustomerOrdersVM(
        inProgressOrderRows,
        submittedOrderRows,
        pastOrdersRows,
        customers,
        platformType,
        customerOrdersCounts,
        selectedCustomer,
      );

      return myOrders;
    },
  );

export const selectImportedOrdersViewModel = (platformType: PlatformEnum) =>
  createSelector(
    selectAppStateUser,
    selectAppStateSelectedCustomer,
    orderSelectors.selectOrderContextState,
    IMPORTED_ORDERS_FILTERS_SELECTORS.selectImportedOrdersFilters,
    getCustomers,
    selectAllFeaturesEnabled([FEATURES.split_global_national_customers.name]),
    partnerSelectors.selectPartner,
    IMPORT_ORDERS_SELECTORS.selectImportOrderCheck,
    selectAllFeaturesEnabled([FEATURES.split_global_modify_vs_order.name]),
    selectOrderStatusDisplayEnabled(),
    (
      loggedInUser: UserState,
      selectedCustomer: SelectedCustomerState,
      orderHeaderState: OrderState,
      filters: ImportedOrdersFiltersState,
      customers: Customer[],
      restrictedNationalFlagEnabled: boolean,
      partnerState: PartnerState,
      importOrderCheck: ImportOrderCheck,
      modifyVSOrdersEnabled: boolean,
      orderStatusDisplayEnabled: boolean,
    ): ImportedOrdersViewModel => {
      const listWmtOrderNumber =
        OrderHeaderService.getListOfWMTOrders(orderHeaderState);
      const defaultFilterStart = new Date();

      const defaultDateFilter = {
        importedDate: `${
          defaultFilterStart.getMonth() + 1
        }/${defaultFilterStart.getDate()}/${defaultFilterStart.getFullYear()}`,
      };

      const customerDictionary = {};
      customers.map(c => {
        customerDictionary[c.customerNumber] = c;
      });

      const submittedWithExceptionsRows: OrderRow[] = Object.values(
        orderHeaderState.entities,
      )
        .filter((order: Order) => {
          if (
            !OrderStatusDetails[order.orderHeader.orderStatus]?.title ||
            order.orderHeader.orderStatus === OrderStatus.DELETED ||
            order.orderHeader.orderStatus === OrderStatus.CANCELLED
          ) {
            return false;
          }
          if (
            order?.orderHeader?.importOrderTakerId !==
              loggedInUser?.ecomUserId.toString() &&
            order?.orderHeader?.importOrderTakerId !== loggedInUser?.userId
          ) {
            return false;
          }
          if (
            order?.orderHeader?.orderStatus !==
            OrderStatus.SUBMITTED_WITH_EXCEPTIONS
          ) {
            return false;
          }
          if (
            !!importOrderCheck?.importOrderIdentifier &&
            filters?.filters?.importedDate === defaultDateFilter.importedDate &&
            !filters?.filters?.showAllOrdersForDate &&
            order?.orderHeader?.importOrderIdentifier !==
              importOrderCheck?.importOrderIdentifier
          ) {
            return false;
          }
          return true;
        })
        .filter(
          (order: Order) =>
            order.orderHeader.orderStatus !== OrderStatus.ORDER_CANCELLED ||
            orderStatusDisplayEnabled,
        )
        .map(order =>
          buildOrderRow(
            order,
            listWmtOrderNumber,
            customerDictionary,
            loggedInUser,
            selectedCustomer,
            restrictedNationalFlagEnabled,
            false,
            null,
            null,
            modifyVSOrdersEnabled,
            orderStatusDisplayEnabled,
            UserKinds.External,
            [],
          ),
        )
        .sort((a, b) => sortOrderRows(a, b, false));

      const submittedRows: OrderRow[] = Object.values(orderHeaderState.entities)
        .filter((order: Order) => {
          if (
            !OrderStatusDetails[order.orderHeader.orderStatus]?.title ||
            order.orderHeader.orderStatus === OrderStatus.DELETED ||
            order.orderHeader.orderStatus === OrderStatus.CANCELLED
          ) {
            return false;
          }
          if (
            order?.orderHeader?.importOrderTakerId !==
              loggedInUser?.ecomUserId.toString() &&
            order?.orderHeader?.importOrderTakerId !== loggedInUser?.userId
          ) {
            return false;
          }
          if (
            order?.orderHeader?.orderStatus !== OrderStatus.SUBMITTED &&
            order?.orderHeader?.orderStatus !==
              OrderStatus.SUBMITTED_CREDIT_HOLD
          ) {
            return false;
          }
          if (
            !!importOrderCheck?.importOrderIdentifier &&
            filters?.filters?.importedDate === defaultDateFilter.importedDate &&
            !filters?.filters?.showAllOrdersForDate &&
            order?.orderHeader?.importOrderIdentifier !==
              importOrderCheck?.importOrderIdentifier
          ) {
            return false;
          }
          return true;
        })
        .filter(
          (order: Order) =>
            order.orderHeader.orderStatus !== OrderStatus.ORDER_CANCELLED ||
            orderStatusDisplayEnabled,
        )
        .map(order =>
          buildOrderRow(
            order,
            listWmtOrderNumber,
            customerDictionary,
            loggedInUser,
            selectedCustomer,
            restrictedNationalFlagEnabled,
            false,
            null,
            null,
            modifyVSOrdersEnabled,
            orderStatusDisplayEnabled,
            UserKinds.External,
          ),
        )
        .sort((a, b) => sortOrderRows(a, b, false));

      const orderCounts: ImportedOrdersCounts = {
        submittedCount: 0,
        submittedWithExceptionsCount: 0,
        totalCount: 0,
      };
      const filteredExceptionsRows = submittedWithExceptionsRows
        .filter(row => {
          if (!filters.filtersSelected) {
            return true;
          }
          const importedDateFilter = {
            importedDate: filters.filters.importedDate,
          };
          return filterOrders(importedDateFilter, row.orderHeader);
        })
        .filter(row => {
          orderCounts.submittedWithExceptionsCount++;
          orderCounts.totalCount++;
          if (!filters.filtersSelected) {
            return true;
          }
          return filterOrders(filters.filters, row.orderHeader);
        });

      const filteredSubmittedRows = submittedRows
        .filter(row => {
          if (!filters.filtersSelected) {
            return true;
          }
          const importedDateFilter = {
            importedDate: filters.filters.importedDate,
          };
          return filterOrders(importedDateFilter, row.orderHeader);
        })
        .filter(row => {
          orderCounts.submittedCount++;
          orderCounts.totalCount++;
          if (!filters.filtersSelected) {
            return true;
          }
          return filterOrders(filters.filters, row.orderHeader);
        });

      const importedOrders: ImportedOrdersViewModel = buildImportedOrdersVM(
        filteredExceptionsRows,
        filteredSubmittedRows,
        customers,
        filters,
        platformType,
        defaultDateFilter,
        orderCounts,
      );
      return importedOrders;
    },
  );

export const selectOrderWithTandemNumber = (tandemNumber: number) =>
  createSelector(
    orderSelectors.selectOrderContextState,
    (orderState: OrderState): Order => {
      const orderId = (orderState?.ids as Array<string>).find(
        id =>
          orderState?.entities[id]?.orderHeader?.tandemOrderNumber ===
          tandemNumber,
      );
      if (!!orderId) {
        return orderState?.entities[orderId];
      } else {
        return null;
      }
    },
  );
// this is made to flatten the form value and identify how many filters are selected
// it takes an objet of object (form value) and turn it into an array of the values
export const flattenToArr = obj => {
  let res = [];
  if (!obj) {
    return [false];
  }
  Object.values(obj).map(o => {
    if (typeof o === 'object') {
      res = [...res, ...flattenToArr(o)];
    } else {
      res.push(o);
    }
  });
  return res;
};

const getCustomerOrdersRows = (
  orderState: OrderState,
  selectedCustomer: SelectedCustomerState,
  listWmtOrderNumber: Set<number>,
  customerDictionary: Object,
  loggedInUser: UserState,
  restrictedNationalFlagEnabled: boolean,
  submittedOnly: boolean,
  pastOrdersOnly: boolean,
  partnerState: PartnerState,
  siteCustomizations: SiteCustomizationProfile,
  modifyVSOrdersEnabled: boolean,
  orderStatusDisplayEnabled: boolean,
  reviewedOrders: OrderReviewPreferences[],
  userKind: UserKinds,
) => {
  const orderRows: OrderRow[] = Object.values(orderState.entities)
    .filter((order: Order) => {
      if (
        (!order.orderHeader.orderId ||
          !OrderStatusDetails[order.orderHeader.orderStatus]?.title ||
          order.orderHeader.orderStatus === OrderStatus.DELETED ||
          order.orderHeader.orderStatus === OrderStatus.CANCELLED) &&
        !pastOrdersOnly
      ) {
        return false;
      }

      if (!pastOrdersOnly) {
        return (
          order.orderHeader.customerNumber ===
            selectedCustomer?.customerNumber &&
          (submittedOnly
            ? order.orderHeader.tandemOrderNumber
            : !order.orderHeader.tandemOrderNumber)
        );
      } else {
        return (
          order.orderHeader.customerNumber ===
            selectedCustomer?.customerNumber &&
          order.orderHeader.orderStatus === OrderStatus.TANDEM_DELETED &&
          dateIsWithinSixWeeks(order.orderHeader.confirmedDeliveryDate) &&
          (order.orderHeader.orderType === 'RT' ||
            order.orderHeader.orderType === 'CC' ||
            order.orderHeader.orderType === 'VS' ||
            order.orderHeader.orderType === 'WC') &&
          !!order.orderItems.find(
            item => !!item.unitsOrdered || !!item.eachesOrdered,
          )
        );
      }
    })
    .filter(
      (order: Order) =>
        order.orderHeader.orderStatus !== OrderStatus.ORDER_CANCELLED ||
        orderStatusDisplayEnabled,
    )
    .map((order: Order) => {
      const orderRow: OrderRow = buildOrderRow(
        order,
        listWmtOrderNumber,
        customerDictionary,
        loggedInUser,
        selectedCustomer,
        restrictedNationalFlagEnabled,
        pastOrdersOnly,
        partnerState,
        siteCustomizations,
        modifyVSOrdersEnabled,
        orderStatusDisplayEnabled,
        userKind,
        reviewedOrders,
      );

      return orderRow;
    })
    .sort((a, b) => {
      return sortOrderRows(a, b, false);
    });
  return orderRows;
};

export const filterOrders = (filters, orderHeader) => {
  orderHeader =
    orderHeader.orderStatus === OrderStatus.INVOICE &&
    orderHeader.orderType === 'VS'
      ? { ...orderHeader, orderStatus: OrderStatus.SHIPPED }
      : orderHeader;

  // store the filter result for each filter type
  const filterFlags = [];
  // if any customers is selected => filter by customer
  if (filters.customers && flattenToArr(filters.customers).some(x => x)) {
    if (typeof filters.customers[orderHeader.customerNumber] === 'object') {
      filterFlags.push(
        filters.customers[orderHeader.customerNumber][
          orderHeader.departmentNumber
        ],
      );
    } else {
      filterFlags.push(filters.customers[orderHeader.customerNumber]);
    }
  }
  // if any orderStatus is selected => filter by order status
  if (filters.orderStatus && Object.values(filters.orderStatus).some(x => x)) {
    if (
      (orderHeader.orderStatus === OrderStatus.SUBMITTED_CREDIT_HOLD &&
        filters.orderStatus.SUBMITTED) ||
      ((orderHeader.orderDelivered ?? false) && filters.orderStatus.DELIVERED)
    ) {
      filterFlags.push(true);
    } else if (
      orderIsSubmissionError(orderHeader) &&
      filters.orderStatus.SUBMISSION_ERROR
    ) {
      filterFlags.push(true);
    } else {
      filterFlags.push(filters.orderStatus[orderHeader.orderStatus]);
    }
  } else {
    filterFlags.push(true);
  }
  // if any delivery date is selected => filter by delivery date
  if (
    filters.deliveryDate &&
    filters.deliveryDate.start &&
    filters.deliveryDate.end
  ) {
    if (filters.deliveryDate.start === '' || filters.deliveryDate.end === '') {
      filterFlags.push(true);
    } else {
      const deliveryDateKey = MyOrdersService.getDateKey(
        new Date(orderHeader.confirmedDeliveryDate),
      );
      const deliveryDate = new Date(deliveryDateKey);
      const startDate = new Date(filters.deliveryDate.start);
      const endDate = new Date(filters.deliveryDate.end);

      if (
        deliveryDate &&
        deliveryDate >= startDate &&
        deliveryDate <= endDate
      ) {
        filterFlags.push(true);
      } else {
        filterFlags.push(false);
      }
    }
  }

  // if any delivery method is selected => filter by delivery method
  if (
    filters.deliveryMethod &&
    Object.values(filters.deliveryMethod).some(x => x)
  ) {
    const deliveryMethod = DeliveryMethodAbbreviation[orderHeader.orderType];
    const isMatchingOrder = filters.deliveryMethod[deliveryMethod];
    filterFlags.push(!!isMatchingOrder);
  }

  if (filters.importedDate) {
    if (filters.importedDate === '') {
      filterFlags.push(true);
    } else {
      const importOrderDtm = new Date(orderHeader.importOrderDtm);
      if (!isNaN(importOrderDtm.getTime())) {
        const importedDateKey = `${
          importOrderDtm.getMonth() + 1
        }/${importOrderDtm.getDate()}/${importOrderDtm.getFullYear()}`;
        if (importedDateKey && importedDateKey === filters.importedDate) {
          filterFlags.push(true);
        } else {
          filterFlags.push(false);
        }
      } else {
        filterFlags.push(false);
      }
    }
  }
  // check that every filter type returned true
  return filterFlags.length > 0 && filterFlags.every(x => x);
};

export const buildOrderRow = (
  order: Order,
  listWmtOrderNumber: Set<number>,
  customerDictionary: {},
  loggedInUser: UserState,
  selectedCustomer: SelectedCustomerState,
  restrictedNationalFlagEnabled: boolean,
  isPastOrderRow: boolean,
  partnerState: PartnerState,
  siteCustomizations: SiteCustomizationProfile,
  modifyVSOrdersEnabled: boolean,
  orderStatusDisplayEnabled: boolean,
  userKind: UserKinds,
  reviewedOrders?: OrderReviewPreferences[],
) => {
  let wmtDeliveryDate = undefined;
  let orderStatusDetails =
    (order?.orderHeader?.orderDelivered ?? false) ||
    !!order?.orderHeader?.deliveredDtm
      ? OrderStatusDetails.DELIVERED
      : OrderStatusDetails[order.orderHeader.orderStatus];

  if (
    orderStatusDisplayEnabled &&
    OrderHeaderService.orderIsPreparingToShip(order?.orderHeader)
  ) {
    orderStatusDetails = OrderStatusDetails.PREPARING_TO_SHIP;
  } else if (
    order.orderHeader.orderStatus == OrderStatus.SUBMITTING &&
    orderIsSubmissionError(order?.orderHeader)
  ) {
    orderStatusDetails = OrderStatusDetails.SUBMISSION_ERROR;
  }

  if (
    !isPastOrderRow &&
    order?.orderHeader?.orderType === 'VS' &&
    (order?.orderHeader?.orderStatus === OrderStatus.INVOICE ||
      !!order?.orderHeader?.orderTracking?.length ||
      order?.orderHeader?.trackingAvailable)
  ) {
    orderStatusDetails = OrderStatusDetails.SHIPPED;
  }
  if (
    orderStatusDetails != OrderStatusDetails.DELIVERED &&
    listWmtOrderNumber.has(order.orderHeader.tandemOrderNumber) &&
    !order?.orderHeader?.etaRangeMinimum &&
    !order?.orderHeader?.etaRangeMaximum &&
    order.orderHeader?.deliveredDtm
  ) {
    wmtDeliveryDate = OrderHeaderService.getDeliveryDateRange(
      order.orderHeader.deliveredDtm,
      order.orderHeader.deliveryEta,
      order.orderHeader.orderDelivered ?? false,
      order.orderHeader.etaRangeMinimum,
      order.orderHeader.etaRangeMaximum,
    );
    if (order?.orderHeader?.orderDelivered ?? false) {
      orderStatusDetails = OrderStatusDetails[OrderStatus.DELIVERED];
    }
  }

  if (
    orderStatusDisplayEnabled &&
    order?.orderHeader?.displayOrderStatus &&
    order?.orderHeader?.orderType === 'VS'
  ) {
    switch (order.orderHeader.displayOrderStatus) {
      case OrderStatus.QUANTITIES_UPDATED:
        orderStatusDetails = OrderStatusDetails.QUANTITIES_UPDATED;
        break;
      case OrderStatus.OUT_FOR_DELIVERY:
        orderStatusDetails = OrderStatusDetails.OUT_FOR_DELIVERY;
        break;
      case OrderStatus.DELIVERY_DELAYED:
        orderStatusDetails = OrderStatusDetails.DELIVERY_DELAYED;
        break;
      case OrderStatus.ATTEMPTED_DELIVERY:
        orderStatusDetails = OrderStatusDetails.ATTEMPTED_DELIVERY;
        break;
      case OrderStatus.ORDER_CANCELLED:
        orderStatusDetails = OrderStatusDetails.ORDER_CANCELLED;
        break;
      case OrderStatus.DELIVERY_ISSUE:
        orderStatusDetails = OrderStatusDetails.DELIVERY_ISSUE;
        break;
      case OrderStatus.DELIVERED:
        orderStatusDetails = OrderStatusDetails.DELIVERED;
        break;
    }
  }

  let displayTitle = '';
  if (order) {
    displayTitle = OrderHeaderService.getDisplayTitle(
      orderStatusDetails,
      listWmtOrderNumber.has(order.orderHeader.tandemOrderNumber),
      order.orderHeader.etaRangeMinimum,
      order.orderHeader.etaRangeMaximum,
      order.orderHeader.orderDelivered ?? false,
      order,
      siteCustomizations,
      partnerState,
    );
  }

  const customerForOrder = customerDictionary[order.orderHeader.customerNumber];
  let restrictToOG = false;

  if (restrictedNationalFlagEnabled) {
    restrictToOG =
      customerForOrder?.restrictToOG === 'Y' ||
      customerForOrder?.restrictToOG === 'M' ||
      customerForOrder?.customerType === 'NA';
  }
  const tallies: MyOrdersTallies = {
    totalItems:
      (order?.orderHeader?.totalUnits || 0) +
      (order?.orderHeader?.totalEaches || 0),
    totalCases: order?.orderHeader?.totalUnits,
    totalEaches: order?.orderHeader?.totalUnits,
    totalCasesOrdered: order?.orderItems?.reduce(
      (accumulator, orderItem) =>
        accumulator + (orderItem?.unitsOrdered?.currentValue || 0),
      0,
    ),
    totalCasesReserved: order?.orderItems?.reduce(
      (accumulator, orderItem) => accumulator + (orderItem?.unitsReserved || 0),
      0,
    ),
    totalEachesOrdered: order?.orderItems?.reduce(
      (accumulator, orderItem) =>
        accumulator + (orderItem?.eachesOrdered?.currentValue || 0),
      0,
    ),
    totalEachesReserved: order?.orderItems?.reduce(
      (accumulator, orderItem) =>
        accumulator + (orderItem?.eachesReserved || 0),
      0,
    ),
  };

  const orderRow: OrderRow = new OrderRow();
  orderRow.restrictToOG = restrictToOG;
  orderRow.orderHeader = !isPastOrderRow
    ? setOrderRowHeader(order.orderHeader, partnerState)
    : order.orderHeader;
  orderRow.tallies = tallies;
  orderRow.displayTitle = displayTitle;
  orderRow.displayType = orderStatusDetails.displayType;
  orderRow.itemInCart = order.orderItems?.length > 0;
  orderRow.iconUrl = orderStatusDetails.icon;
  orderRow.customer = customerForOrder;
  orderRow.isEditable =
    OrderHeaderService.isOrderEditable(
      order.orderHeader,
      modifyVSOrdersEnabled,
    ) && !restrictToOG;
  orderRow.isCancellable =
    OrderHeaderService.isOrderCancellable(
      order.orderHeader,
      modifyVSOrdersEnabled,
    ) && !restrictToOG;
  orderRow.deliveryDate =
    !!order.orderHeader.confirmedDeliveryDate &&
    orderStatusDetails !== OrderStatusDetails.ORDER_CANCELLED
      ? order.orderHeader.confirmedDeliveryDate
      : 'N/A';
  orderRow.deliveredDtm = order.orderHeader.deliveredDtm;
  orderRow.wmtDeliveryDate = wmtDeliveryDate;
  orderRow.hasDeliveryInfo =
    (order.orderHeader.trackingAvailable &&
      !!order.orderHeader.orderTracking?.length) ||
    !!wmtDeliveryDate;
  orderRow.isTmUser = loggedInUser?.tmUser === 'Y';
  orderRow.currentCustomerInState = selectedCustomer;
  orderRow.orderDelivered = order.orderHeader.orderDelivered;
  orderRow.hasETADetails =
    !order.orderHeader.orderDelivered &&
    (!!wmtDeliveryDate ||
      (!!order.orderHeader.etaRangeMaximum &&
        !!order.orderHeader.etaRangeMinimum &&
        isValidDate(order.orderHeader.etaRangeMinimum) &&
        isValidDate(order.orderHeader.etaRangeMaximum)));
  orderRow.modifyLinkText = partnerState?.punchoutProfile?.updateButton;
  orderRow.totalPrice = calculateTotalPrice(order?.orderHeader);
  orderRow.orderStatusDisplayEnabled = orderStatusDisplayEnabled;
  orderRow.orderViewed = OrderReviewedService.isMyOrderViewed(
    userKind,
    order.orderHeader,
    orderRow,
    reviewedOrders,
  );

  if (isPastOrderRow) {
    const pastOrder = {
      ...orderRow,
    } as PastOrdersRow;
    const orderItems = order.orderItems;
    return { ...pastOrder, orderItems };
  }
  return orderRow;
};

const sortOrderRows = (
  a: OrderRow | NoOrderRow,
  b: OrderRow | NoOrderRow,
  sortForOrderStatus: boolean,
): number => {
  const aCustomer = a.customer;
  const bCustomer = b.customer;

  const aOrderHeader = a.orderHeader;
  const bOrderHeader = b.orderHeader;
  const aOrderStatusDetails = OrderStatusDetails[aOrderHeader.orderStatus];
  const bOrderStatusDetails = OrderStatusDetails[bOrderHeader.orderStatus];

  let aDate;
  let bDate;
  if (!sortForOrderStatus) {
    if (aOrderHeader.tandemOrderNumber) {
      if (aOrderHeader.entryDtm) {
        aDate = convertToDate(aOrderHeader.entryDtm);
      } else {
        aDate = convertToDate(aOrderHeader.orderDate);
      }

      if (bOrderHeader.entryDtm) {
        bDate = convertToDate(bOrderHeader.entryDtm);
      } else {
        bDate = convertToDate(bOrderHeader.orderDate);
      }
    } else {
      aDate = convertToDate(aOrderHeader.addDtm);
      bDate = convertToDate(bOrderHeader.addDtm);
    }
  } else {
    aDate = convertToDate(aOrderHeader.confirmedDeliveryDate);
    bDate = convertToDate(bOrderHeader.confirmedDeliveryDate);
  }

  const aDateString = convertToDateString(aDate);
  const bDateString = convertToDateString(bDate);

  if (
    sortForOrderStatus &&
    aDateString === bDateString &&
    aOrderStatusDetails.sortOrder === bOrderStatusDetails.sortOrder
  ) {
    if (aCustomer?.customerName && bCustomer?.customerName) {
      if (aCustomer.customerName === bCustomer.customerName) {
        return aOrderHeader.departmentNumber - bOrderHeader.departmentNumber;
      }
      return aCustomer.customerName.localeCompare(bCustomer.customerName);
    }
  }
  if (sortForOrderStatus && aDateString === bDateString) {
    return aOrderStatusDetails.sortOrder - bOrderStatusDetails.sortOrder;
  }
  if (sortForOrderStatus) {
    return aDate.valueOf() > bDate.valueOf() ? 1 : -1;
  } else {
    return aDate.valueOf() < bDate.valueOf() ? 1 : -1;
  }
};

const sortOrders = (
  a: Order,
  b: Order,
  aCustomer: Customer,
  bCustomer: Customer,
) => {
  const aOrderHeader = a.orderHeader;
  const bOrderHeader = b.orderHeader;
  const aOrderStatusDetails = OrderStatusDetails[aOrderHeader.orderStatus];
  const bOrderStatusDetails = OrderStatusDetails[bOrderHeader.orderStatus];

  const aDate = convertToDate(aOrderHeader.confirmedDeliveryDate);
  const bDate = convertToDate(bOrderHeader.confirmedDeliveryDate);

  const aDateString = convertToDateString(aDate);
  const bDateString = convertToDateString(bDate);

  if (
    aDateString === bDateString &&
    aOrderStatusDetails.sortOrder === bOrderStatusDetails.sortOrder
  ) {
    if (aCustomer?.customerName && bCustomer?.customerName) {
      if (aCustomer.customerName === bCustomer.customerName) {
        return aOrderHeader.departmentNumber - bOrderHeader.departmentNumber;
      }
      return aCustomer.customerName.localeCompare(bCustomer.customerName);
    }
  }
  if (aDateString === bDateString) {
    return aOrderStatusDetails.sortOrder - bOrderStatusDetails.sortOrder;
  }

  return aDate.valueOf() > bDate.valueOf() ? 1 : -1;
};

const buildCustomerOrdersVM = (
  inProgressOrderRows: OrderRow[],
  submittedOrderRows: OrderRow[],
  pastOrdersRows: OrderRow[],
  customers: Customer[],
  platformType: PlatformEnum,
  orderCounts: CustomerOrdersCounts,
  selectedCustomer: SelectedCustomerState,
) => {
  const orderAndTitleRows = [];
  const itemHeights = [];
  const pastOrdersAndTitleRow = [];
  const pastOrderItemHeights = [];

  let titleRowHeight = 300;
  let orderRowHeight = 200;
  let footerRowHeight = 328.5;

  if (platformType === PlatformEnum.desktop) {
    //As banner is inside cdk-virtual-scroll-viewport, add Banner Height
    itemHeights.push(87);
  }
  //order-list-header height is accounted for in the first date row height (don't push that in)
  else if (platformType === PlatformEnum.tablet) {
    titleRowHeight = 120.2;
    //order height + lower margin
    orderRowHeight = 141;
    //order height + lower margin
    footerRowHeight = 50;
  } else if (platformType === PlatformEnum.mobile) {
    titleRowHeight = 100.2;
    //order row height + upper margin
    orderRowHeight = 170;
    footerRowHeight = 50;
  }

  const submittedOrderTitleRow: TitleRow = new TitleRow(
    'i18n.orderStatusPage.submittedOrders',
    orderCounts.submittedCount,
    DisplayType.SUBMITTED,
  );
  orderAndTitleRows.push(submittedOrderTitleRow);
  itemHeights.push(titleRowHeight);

  submittedOrderRows.forEach(order => {
    orderAndTitleRows.push(order);
    itemHeights.push(orderRowHeight);
  });

  const inProgressOrderTitleRow: TitleRow = new TitleRow(
    'i18n.orderStatusPage.openOrders',
    orderCounts.openOrdersCount,
    DisplayType.IN_PROGRESS,
  );
  orderAndTitleRows.push(inProgressOrderTitleRow);
  itemHeights.push(titleRowHeight);

  inProgressOrderRows.forEach(order => {
    orderAndTitleRows.push(order);
    itemHeights.push(orderRowHeight);
  });

  const pastOrdersTitleRow: TitleRow = new TitleRow(
    'i18n.orderStatusPage.pastOrders',
    pastOrdersRows.length,
    DisplayType.IN_PROGRESS,
  );
  pastOrdersAndTitleRow.push(pastOrdersTitleRow, ...pastOrdersRows);
  pastOrderItemHeights.push(titleRowHeight);
  pastOrdersRows.forEach(order => {
    pastOrderItemHeights.push(orderRowHeight);
  });

  itemHeights.push(footerRowHeight);
  const customerOrdersVM: CustomerOrdersViewModel = {
    myOrdersRows: orderAndTitleRows,
    pastOrdersRows: pastOrdersAndTitleRow,
    customers,
    itemHeights,
    pastOrderItemHeights,
    orderCounts,
    selectedCustomer,
  };

  return customerOrdersVM;
};

const buildMyOrdersVM = (
  myOrders: (OrderRow | NoOrderRow)[],
  customers: Customer[],
  filters: any,
  platformType: PlatformEnum,
  defaultDateFilter: Object,
  filterCounts: MyOrdersFilterCounts,
  reviewedOrders: OrderReviewPreferences[],
  userKind: UserKinds,
) => {
  let previousSectionDateTitle: string = null;
  const orderAndDateRows = [];
  const itemHeights = [];

  let dateRowHeight = 300;
  let orderRowHeight = 200;
  let noOrderRowHeight = 50;
  let footerRowHeight = 328.5;

  if (platformType === PlatformEnum.desktop) {
    //As banner is inside cdk-virtual-scroll-viewport, add Banner Height
    itemHeights.push(87);
  }
  //order-list-header height is accounted for in the first date row height (don't push that in)
  else if (platformType === PlatformEnum.tablet) {
    //date height + upper padding + upper margin + lower margin
    // dateRowHeight = 120.2;
    //order height + lower margin
    orderRowHeight = 141;
    //order height + lower margin
    noOrderRowHeight = 90;
    footerRowHeight = 50;
  } else if (platformType === PlatformEnum.mobile) {
    //date height + upper padding + upper margin; ignore lower margin as that is accounted for in the order row heights
    // dateRowHeight = 100.2;
    //order row height + upper margin
    orderRowHeight = 170;
    noOrderRowHeight = 80;
    footerRowHeight = 50;
  }

  //Breakdown orders into
  myOrders.forEach(order => {
    const sectionDate: moment.Moment = convertToDate(
      order.orderHeader.confirmedDeliveryDate,
    );
    const sectionDateTitle = convertToDateString(sectionDate);

    //If order is of new date then add a date section
    if (previousSectionDateTitle !== sectionDateTitle) {
      const today = moment().startOf('day');
      const tomorrow = moment().add(1, 'day').startOf('day');

      let dateDisplayTitle = sectionDateTitle;

      if (convertToDateString(today) === sectionDateTitle) {
        dateDisplayTitle = 'Today, ' + sectionDateTitle;
      } else if (convertToDateString(tomorrow) === sectionDateTitle) {
        dateDisplayTitle = 'Tomorrow, ' + sectionDateTitle;
      }

      const dateRow: DateRow = new DateRow(dateDisplayTitle);
      previousSectionDateTitle = sectionDateTitle;
      orderAndDateRows.push(dateRow);
      //Push date row height
      itemHeights.push(dateRowHeight);
    }

    order.orderViewed = OrderReviewedService.isOrderStatusRowViewed(
      userKind,
      order,
      reviewedOrders,
    );
    orderAndDateRows.push(order);
    //Push order row height
    if (order.rowType === MyOrdersRowType.NoOrderRow) {
      itemHeights.push(noOrderRowHeight);
    } else {
      itemHeights.push(orderRowHeight);
    }
  });

  const quickFilters: QuickFilter[] = [
    {
      id: MyOrdersQuickFilters.MY_DELIVERIES,
      title: 'My Deliveries',
      color: 'green',
      count: filterCounts.myDeliveriesCount,
      selected:
        filters?.filters?.orderStatus?.SHIPPED &&
        filters?.filters?.orderStatus?.DELIVERED,
      translationKey: 'i18n.orderStatusPage.myDeliveries',
    },
    {
      id: OrderStatus.SUBMITTED,
      title: OrderStatusDetails[OrderStatus.SUBMITTED]?.title,
      count: filterCounts.submittedCount,
      color: 'green',
      selected: filters?.filters?.orderStatus?.SUBMITTED,
      translationKey: 'i18n.orderStatusPage.submitted',
    },
    {
      id: OrderStatus.SUBMITTED_WITH_EXCEPTIONS,
      title: OrderStatusDetails[OrderStatus.SUBMITTED_WITH_EXCEPTIONS]?.title,
      count: filterCounts.submittedWithExceptionsCount,
      selected: filters?.filters?.orderStatus?.SUBMITTED_WITH_EXCEPTIONS,
      translationKey: 'i18n.orderStatusPage.submittedWExceptions',
    },
    {
      id: MyOrdersQuickFilters.OPEN_ORDERS,
      title: 'Open Orders',
      count: filterCounts.openOrdersCount,
      selected:
        filters?.filters?.orderStatus?.IN_PROGRESS &&
        filters?.filters?.orderStatus?.NO_ORDER,
      translationKey: 'i18n.orderStatusPage.openOrders',
    },
  ];
  itemHeights.push(footerRowHeight);
  const myOrderV2: MyOrdersViewModel = {
    myOrdersRows: orderAndDateRows,
    customers,
    filters,
    itemHeights,
    defaultDateFilter,
    filterCounts,
    quickFilters,
  };

  return myOrderV2;
};

const buildImportedOrdersVM = (
  submittedWithExceptionsRows: OrderRow[],
  submittedRows: OrderRow[],
  customers: Customer[],
  filters: any,
  platformType: PlatformEnum,
  defaultDateFilter: Object,
  orderCounts: ImportedOrdersCounts,
) => {
  const itemHeights = [];
  const orderAndTitleRows = [];

  let titleRowHeight = 300;
  let orderRowHeight = 200;
  let footerRowHeight = 328.5;

  if (platformType === PlatformEnum.desktop) {
    //As banner is inside cdk-virtual-scroll-viewport, add Banner Height
    itemHeights.push(87);
  }
  //order-list-header height is accounted for in the first date row height (don't push that in)
  else if (platformType === PlatformEnum.tablet) {
    titleRowHeight = 120.2;
    //order height + lower margin
    orderRowHeight = 141;
    //order height + lower margin
    footerRowHeight = 50;
  } else if (platformType === PlatformEnum.mobile) {
    titleRowHeight = 100.2;
    //order row height + upper margin
    orderRowHeight = 170;
    footerRowHeight = 50;
  }

  const quickFilters: QuickFilter[] = [
    {
      id: ImportedOrdersQuickFilters.ALL_IMPORTED_ORDERS,
      title: 'All Imported Orders',
      color: 'green',
      count: orderCounts.totalCount,
      selected:
        !filters?.filters?.orderStatus?.SUBMITTED_WITH_EXCEPTIONS &&
        !filters?.filters?.orderStatus?.SUBMITTED,
    },
    {
      id: OrderStatus.SUBMITTED_WITH_EXCEPTIONS,
      title: OrderStatusDetails[OrderStatus.SUBMITTED_WITH_EXCEPTIONS]?.title,
      count: orderCounts.submittedWithExceptionsCount,
      selected: filters?.filters?.orderStatus?.SUBMITTED_WITH_EXCEPTIONS,
    },
    {
      id: OrderStatus.SUBMITTED,
      title: OrderStatusDetails[OrderStatus.SUBMITTED]?.title,
      count: orderCounts.submittedCount,
      color: 'green',
      selected: filters?.filters?.orderStatus?.SUBMITTED,
    },
  ];

  if (submittedWithExceptionsRows.length > 0) {
    const submittedWithExceptionsTitleRow: TitleRow = new TitleRow(
      'i18n.confirmationPage.submittedWithExceptions',
      orderCounts.submittedWithExceptionsCount,
      DisplayType.SUBMITTED_WITH_EXCEPTIONS,
    );
    orderAndTitleRows.push(submittedWithExceptionsTitleRow);
    itemHeights.push(titleRowHeight);
    itemHeights.push(footerRowHeight);
  }
  submittedWithExceptionsRows.forEach(order => {
    orderAndTitleRows.push(order);
    itemHeights.push(orderRowHeight);
  });

  if (submittedRows.length > 0) {
    const submittedOrderTitleRow: TitleRow = new TitleRow(
      'i18n.orderStatusPage.submittedOrders',
      orderCounts.submittedCount,
      DisplayType.SUBMITTED,
    );

    orderAndTitleRows.push(submittedOrderTitleRow);
    itemHeights.push(titleRowHeight);
    itemHeights.push(footerRowHeight);
  }

  submittedRows.forEach(order => {
    orderAndTitleRows.push(order);
    itemHeights.push(orderRowHeight);
  });
  const importedOrdersVm: ImportedOrdersViewModel = {
    myOrdersRows: orderAndTitleRows,
    customers,
    filters,
    itemHeights,
    defaultDateFilter,
    orderCounts,
    quickFilters,
  };
  return importedOrdersVm;
};

const convertToDate = (date: string | Date): moment.Moment => {
  if (!date) {
    return moment.utc(0);
  }
  return moment.utc(date);
};

const convertToDateString = (date?: moment.Moment): string => {
  if (date.valueOf() === 0) {
    return undefined;
  }
  return date.format('MMMM DD, YYYY');
};

export const setOrderRowHeader = (
  orderHeader: OrderHeader,
  partnerState: PartnerState,
) => {
  let header = orderHeader;
  if (
    orderHeader.orderType === 'VS' &&
    (!!orderHeader?.orderTracking?.length || orderHeader?.trackingAvailable)
  ) {
    header = { ...orderHeader, orderStatus: OrderStatus.SHIPPED };
  } else if (
    partnerState?.partnerId &&
    !orderHeader?.purchaseOrderNumber &&
    (orderHeader.orderStatus == OrderStatus.SUBMITTED ||
      orderHeader.orderStatus == OrderStatus.SUBMITTED_WITH_EXCEPTIONS ||
      orderHeader.orderStatus == OrderStatus.SUBMITTED_CREDIT_HOLD)
  ) {
    header = { ...orderHeader, purchaseOrderNumber: PUNCHOUT_PO_PENDING };
  }
  return header;
};

export const isDeliveryDateInFuture = (deliveryDate: string) => {
  const todaysDate = moment().utc().utcOffset(0).format('MM/DD/YY');
  return moment(deliveryDate).isAfter(todaysDate);
};

export const orderIsEmpty = (order: Order) => {
  if (order?.orderItems.length === 0) {
    return true;
  }
  return false;
};

export const orderIsSubmissionError = (orderHeader: OrderHeader) => {
  if (orderHeader.orderStatus !== OrderStatus.SUBMITTING) {
    return false;
  }
  const updateDtm = new Date(orderHeader.updateDtm);
  const now = new Date();
  if (now.getTime() - updateDtm.getTime() > 30 * 60 * 1000) {
    return true;
  }
  return false;
};

export const dateIsWithinSixWeeks = (confirmedDeliveryDate: string | Date) => {
  const currentDate = new Date();
  const sixWeeksAgo = new Date(
    currentDate.getTime() - 6 * 7 * 24 * 60 * 60 * 1000,
  );
  return sixWeeksAgo <= new Date(confirmedDeliveryDate);
};

export const calculateTotalPrice = (orderHeader: OrderHeader): number => {
  return (
    (orderHeader?.totalDollars || 0) +
      (orderHeader?.totalSalesTax || 0) +
      (orderHeader?.totalCharges || 0) -
      Math.abs(orderHeader?.totalAllowances || 0) || 0
  );
};

export const isValidDate = (dateToValidate: any): boolean => {
  try {
    if (new Date(dateToValidate).toString() === 'Invalid Date') return false;
  } catch (e) {
    return false;
  }
  return true;
};
