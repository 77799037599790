<ion-header
  class="ion-no-border"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? 'ion-header-modal-tablet'
      : 'ion-header-modal-desktop'
  "
>
  <ion-toolbar>
    <ion-buttons slot="end">
      <ion-button
        *ngIf="(platformService?.isTouchExperience$ | async) === false"
        id="close-modal-btn"
        data-cy="close-sort-filter-modal-button"
        (click)="dismissModal()"
      >
        <ion-icon slot="icon-only" name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-label data-cy="sort-filter-label">
      {{ 'i18n.lists.sortAndFilter' | translate }}
    </ion-label>
  </ion-toolbar>
</ion-header>

<ion-content
  class="sort-filter-list_content"
  [ngClass]="
    (platformService?.isTouchExperience$ | async)
      ? 'ion-content-modal-tablet'
      : 'ion-content-modal-desktop'
  "
>
  <ion-grid>
    <ion-row>
      <!-- SORT & FILTER OPTIONS -->
      <ion-col size="5" class="sort-filter-list-options">
        <div class="sort-options-header">
          <ion-label data-cy="sort-by-label">
            {{ 'i18n.common.sortBy' | translate }}
          </ion-label>
        </div>

        <div class="sort-options">
          <ion-item-divider></ion-item-divider>
          <app-highlighted-selectable-item
            *ngIf="!isRecentPurchase"
            [label]="'i18n.lists.groupLine'"
            [value]="
              selectedSort === sortFilterType.groupLine
                ? selectedGroup === listConstants.allGroups
                  ? 'All Groups'
                  : selectedGroup
                : 'All Groups'
            "
            [selected]="selectedSort === sortFilterType.groupLine"
            [index]="1"
            (clickValueEmitter)="setSort(sortFilterType.groupLine)"
          ></app-highlighted-selectable-item>
          <app-highlighted-selectable-item
            *ngIf="!isRecentPurchase"
            [label]="'i18n.lists.groupAlpha'"
            [value]="
              selectedSort === sortFilterType.groupAlpha
                ? selectedGroup === listConstants.allGroups
                  ? 'All Groups'
                  : selectedGroup
                : 'All Groups'
            "
            [selected]="selectedSort === sortFilterType.groupAlpha"
            [index]="2"
            (clickValueEmitter)="setSort(sortFilterType.groupAlpha)"
          ></app-highlighted-selectable-item>
          <app-highlighted-selectable-item
            [label]="'i18n.lists.usFoodsClass'"
            [value]="
              selectedSort === sortFilterType.usFoodsClass
                ? selectedGroup === listConstants.allGroups
                  ? 'All US Foods Class'
                  : selectedGroup?.toLowerCase()
                : 'All US Foods Class'
            "
            [selected]="selectedSort === sortFilterType.usFoodsClass"
            [index]="3"
            (clickValueEmitter)="setSort(sortFilterType.usFoodsClass)"
          ></app-highlighted-selectable-item>
          <ion-item
            [class.selected]="selectedSort === sortFilterType.alphabetical"
            (click)="setSort(sortFilterType.alphabetical)"
            data-cy="click-sort-alphabetical-item"
            class="bold"
          >
            <ion-label id="alphabetical"
              >{{ 'i18n.common.alphabetical' | translate }}
            </ion-label>
            <app-usf-checkbox
              aria-labelledby="alphabetical"
              [invertColors]="true"
              [isSelected]="alphabeticalSelected"
              data-cy="select-alphabetical-checkbox"
            >
            </app-usf-checkbox>
          </ion-item>

          <ion-item
            [class.selected]="selectedSort === sortFilterType.date"
            (click)="setSort(sortFilterType.date)"
            *ngIf="isRecentPurchase"
            data-cy="click-sort-date-item"
          >
            <ion-label id="sort-by-date" data-cy="sort-by-date-label"
              >{{ 'i18n.lists.sortByDate' | translate }}
            </ion-label>
            <app-usf-checkbox
              aria-labelledby="sort-by-date"
              [invertColors]="true"
              [isSelected]="dateSelected"
              data-cy="select-date-checkbox"
            >
            </app-usf-checkbox>
          </ion-item>
        </div>

        <div class="filter-options-header">
          <ion-label data-cy="filter-by-label">
            {{ 'i18n.common.filterBy' | translate }}
          </ion-label>
        </div>
        <ion-item-divider></ion-item-divider>
        <ion-item
          *ngIf="!isRecentPurchase"
          [class.selected]="
            selectedFilters?.includes(sortFilterType.recentlyPurchased)
          "
          (click)="setFilters(sortFilterType.recentlyPurchased)"
          data-cy="click-sort-recently-purchased-item"
        >
          <ion-label id="recently-purchased" data-cy="sort-by-date-label"
            >{{ 'i18n.lists.recentlyPurchased' | translate }}
          </ion-label>
          <app-usf-checkbox
            aria-labelledby="recently-purchased"
            [invertColors]="true"
            [isSelected]="
              selectedFilters?.includes(sortFilterType.recentlyPurchased)
            "
            data-cy="click-sort-recently-purchased-checkbox"
          ></app-usf-checkbox>
        </ion-item>
        <app-highlighted-selectable-item
          *ngIf="isRecentPurchase"
          [label]="'i18n.lists.showProductsFromPast' | translate"
          [value]="
            week52Filter
              ? ('i18n.lists.filterBy52Weeks' | translate)
              : ('i18n.lists.filterBy26Weeks' | translate)
          "
          [selected]="showProductsFromPastOptions"
          [index]="3"
          (clickValueEmitter)="toggleProductsFromPast()"
        >
        </app-highlighted-selectable-item>
        <ion-item
          *ngIf="!isMasterList"
          [class.selected]="
            selectedFilters?.includes(sortFilterType.discontinued)
          "
          (click)="setFilters(sortFilterType.discontinued)"
          data-cy="click-sort-discontinued-item"
        >
          <ion-label id="discontinued" data-cy="sort-by-date-label"
            >{{ 'i18n.lists.discontinued' | translate }}
          </ion-label>
          <app-usf-checkbox
            aria-labelledby="discontinued"
            [invertColors]="true"
            [isSelected]="
              selectedFilters?.includes(sortFilterType.discontinued)
            "
            data-cy="select-discontinued-checkbox"
          ></app-usf-checkbox>
        </ion-item>
      </ion-col>

      <!-- SORTED/FILTERED GROUP CHOICES -->
      <ion-col
        *ngIf="showSelectedUsFoodsClassOptions"
        offset="1.5"
        size="5.5"
        class="sort-filter-group-choices"
      >
        <ion-list>
          <ion-radio-group class="radio-options" [(ngModel)]="selectedGroup">
            <div
              *ngIf="
                selectedSort === sortFilterType.groupAlpha ||
                selectedSort === sortFilterType.groupLine
              "
              class="groups"
            >
              <ion-item-divider></ion-item-divider>
              <ion-item>
                <ion-radio
                  justify="space-between"
                  mode="md"
                  [value]="sortFilterType.allGroups"
                  data-cy="click-all-groups-radio"
                  >{{ 'i18n.lists.allGroups' | translate }}
                </ion-radio>
              </ion-item>
              <ion-item *ngFor="let group of groupNames">
                <ion-radio
                  justify="space-between"
                  mode="md"
                  [value]="group"
                  data-cy="click-sort-group-line-item"
                  >{{ group }}
                </ion-radio>
              </ion-item>
            </div>
            <div
              *ngIf="selectedSort === sortFilterType.usFoodsClass"
              class="groups"
            >
              <ion-item-divider></ion-item-divider>
              <ion-item>
                <ion-radio
                  justify="space-between"
                  mode="md"
                  [value]="sortFilterType.allGroups"
                  data-cy="click-sort-all-usf-classes-radio"
                  >{{ 'i18n.lists.allUsfClasses' | translate }}
                </ion-radio>
              </ion-item>
              <ion-item *ngFor="let class of productClasses">
                <ion-radio
                  justify="space-between"
                  mode="md"
                  [value]="class"
                  data-cy="click-product-class-radio"
                  >{{ class | lowercase }}
                </ion-radio>
              </ion-item>
            </div>
          </ion-radio-group>
        </ion-list>
      </ion-col>
      <ion-col
        *ngIf="isRecentPurchase && showProductsFromPastOptions"
        offset="1.5"
        size="5.5"
        class="sort-filter-group-choices"
      >
        <ion-list>
          <ion-radio-group
            [(ngModel)]="week52Filter"
            (ionChange)="onWeeksFilterChange($event.detail.value)"
          >
            <ion-item-divider></ion-item-divider>
            <ion-item>
              <ion-radio
                justify="space-between"
                mode="md"
                [value]="false"
                data-cy="click-product-class-radio"
              >
                {{ 'i18n.lists.filterBy26Weeks' | translate }}
              </ion-radio>
            </ion-item>
            <ion-item>
              <ion-radio
                justify="space-between"
                mode="md"
                [value]="true"
                data-cy="click-product-class-radio"
              >
                {{ 'i18n.lists.filterBy52Weeks' | translate }}
              </ion-radio>
            </ion-item>
          </ion-radio-group>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer
  class="ion-footer-modal"
  [class.ion-footer-modal-desktop]="
    (platformService?.isTouchExperience$ | async) === false
  "
>
  <ion-toolbar>
    <ion-buttons>
      <ion-button
        id="clear-all-modal-btn"
        data-cy="clear-all-sort-filter-modal-button"
        class="usf-outline-green-dsktp-tblt-modal-button"
        (click)="clearAll()"
      >
        {{ 'i18n.common.clearAll' | translate }}
      </ion-button>
      <ion-button
        id="apply-modal-btn"
        data-cy="apply-sort-filter-modal-button"
        class="usf-fill-green-dsktp-tblt-modal-button"
        (click)="applyAll()"
      >
        {{ 'i18n.common.apply' | translate }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-footer>
